import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Button } from '@mui/material'

import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from 'src/components/Loading'
import StyledTypo from 'src/components/StyledTypo'
import Page from 'src/components/mui/Page'
import { useAuth } from 'src/context/AuthContext'
import { metricsService } from 'src/services/metrices'
import { metricsServiceUsingMetricEndpoint } from 'src/services/metrices/metricServiceExtended'
import { sendSlackNotification } from 'src/utils/api'
import { getFrontendAlertChannel } from 'src/utils/functions/slackSupport'


import { APP_VERSION } from 'src/utils/config/config'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
const appVersion = APP_VERSION

interface TimerRedirectProps {
  to: string
  blockedOnDemo?: boolean
}

function TimerRedirect({ to, blockedOnDemo }: TimerRedirectProps) {
  // Set the initial timer value in seconds
  const [timer, setTimer] = useState(5)
  const navigate = useNavigate()
  const location = useLocation()
  const { currentUser, standaloneRoutesLoaded } = useAuth()

  // Set the initial redirect state to false
  const [redirect, setRedirect] = useState(false)
  const [linkFound, setLinkFound] = useState(false)

  // Use useEffect hook to update the timer every second
  useEffect(() => {
    if (!standaloneRoutesLoaded) return
    // If timer is zero or redirect is true, stop the effect
    if (timer === 0 || redirect) return

    // Create a timeout to decrement the timer by one after one second
    const timeout = setTimeout(() => {
      setTimer(timer - 1)
    }, 1000)

    // Clear the timeout when the component unmounts or updates
    return () => {
      clearTimeout(timeout)
    }
  }, [timer, redirect, standaloneRoutesLoaded])

  useEffect(() => {
    if (!standaloneRoutesLoaded) return;

    if (process.env.NODE_ENV === 'production' && linkFound) {
      const notFoundText = (blockedOnDemo ? 'Demo User blocked from accessing ' : 'Route not found: ') + location.pathname
      captureException(notFoundText)
    }

    const message = blockedOnDemo ? `Demo User ${currentUser.email} blocked` : `Route not found for ${currentUser.email} of ${currentUser.org};`
    metricsServiceUsingMetricEndpoint.trackError(
      'GENERAL',
      blockedOnDemo ? '403' : '404',
      'unknown', // module
      process.env.NODE_ENV === 'production' ? 'external' : 'internal', // userType
      blockedOnDemo ? "Demo User Blocked" : "Route Not Found",
      -1, // duration
      appVersion,
      document.referrer || window.location.pathname,
      "not applicable",
      currentUser.email,
      currentUser.org,
      getAccessLevel(currentUser), // access level
      'GET',
    )
    sendSlackNotification({
      message: message,
      channel: getFrontendAlertChannel(),
      title: `${blockedOnDemo ? 'Demo user blocked' : 'Route not found'}
              Route: \`${location.pathname}\`
              Redirecting to: \`${to}\`
              `
    })

    metricsService.trackError('route_not_found', '404', 'unknown', message, location.pathname)
  }, [currentUser, linkFound, standaloneRoutesLoaded])

  // Use another useEffect hook to set the redirect state to true when timer reaches zero
  useEffect(() => {
    // If timer is not zero, do nothing
    if (timer !== 0) return

    // Set the redirect state to true after a small delay
    const timeout = setTimeout(() => {
      setRedirect(true)
    }, 500)

    // Clear the timeout when the component unmounts or updates
    return () => {
      clearTimeout(timeout)
    }
  }, [timer])

  // this use effect handles the navigation
  useEffect(() => {
    if (redirect && standaloneRoutesLoaded) {
      setLinkFound(true)
      if (window.history.length > 2 && blockedOnDemo) navigate(-1)
      else navigate(to)
    }
  }, [redirect, blockedOnDemo, standaloneRoutesLoaded])

  if (!standaloneRoutesLoaded) {
    return (
      <Box sx={{ "position": "relative" }}>
       <Loading />
      </Box>)
    }


  // Render the timer value or a redirect component based on the redirect state
  return !redirect && standaloneRoutesLoaded &&  (
    <Page title={blockedOnDemo ? "Access Denied" : "Not Found"} copyUrl={false} hideFilters>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
        <ErrorOutlineIcon sx={{ fontSize: 100 }} />
        <StyledTypo fs={34}>{blockedOnDemo ? "This feature is not accessible in demo mode" : "Sorry!! The page you're trying to access is not available"}</StyledTypo>
        <StyledTypo
          fw={400}
          sx={{ my: 2 }}>
          Redirecting in: {timer} seconds
        </StyledTypo>
        <Button onClick={() => navigate(to)}>Go to Home</Button>
      </div>
    </Page>
  )
}

export default TimerRedirect
