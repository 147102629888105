import { Typo } from '@LoopKitchen/loop-ui'
import { Box, Typography } from '@mui/material'
import { useMemo } from 'react'
import PlatformLogo from 'src/components/PlatformLogo'
import DataComp from 'src/pages/members/Finance/components/DataComp'
import { rowFlex } from 'src/pages/members/Finance/utils/constants'
import { usePayoutsBreakdownDataContext } from 'src/pages/members/Finance/utils/context/PayoutsBreakdownDataContext'
import { OrderBreakdownResponse, PLATFORM } from 'src/services/openApiV2'
import { platformNames } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import { useTransactionsBreakdownDataContext } from '../../../utils/context/TransactionsBreakdownDataContext'

const showForPlatforms = (platforms: string[], title: string) => {
  return platforms.includes(title) || title === 'Total'
}

const BreakdownByPlatform = ({
  label,
  data,
  image,
  fw,
  platformBreakdownLoading,
  oloExists
}: {
  label: string
  data: OrderBreakdownResponse
  image?: string
  fw?: number
  platformBreakdownLoading: boolean
  oloExists?: boolean
}) => {
  const { fontSizes, zoom } = usePayoutsBreakdownDataContext()
  const { breakdownData } = useTransactionsBreakdownDataContext()

  const otherTotal = useMemo(() => {
    return (
      get(data, 'values.customer_tip', 0) + get(data, 'values.restaurant_fees', 0) + get(data, 'values.miscellaneous', 0) + get(data, 'values.unaccounted', 0)
    )
  }, [data])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '0 5px'
      }}>
      <Box
        sx={{
          borderRadius: '8px',
          height: zoom >= 67 ? '50px' : '60px',
          marginBottom: '10px',
          border: '1px solid #E6E6E6',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        {image && (
          <PlatformLogo
            platformName={image}
            shortImg
            width="24px"
            height="24px"
            boxSx={{ marginRight: '5px', flex: '0 !important', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          />
        )}
        <Typo
          fs={fontSizes['14']}
          fw={fw ? fw : 400}
          sx={{ display: 'flex', justifyContent: 'center' }}>
          {platformNames[label as PLATFORM] || label}
        </Typo>
      </Box>

      <DataComp
        sx={{ bgcolor: label === 'Total' ? 'rgba(47, 74, 90, 1)' : '#FFFFFF', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '35px' : '45px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '6px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['16'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: label === 'Total' ? '#FFFFFF' : '#09234B'
                      }}>
                      {formatCurrency(get(data, 'values.subtotal_with_tax', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{ bgcolor: '#E3EDF0', color: '#196E82', p: '4px 7px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['11'] }}>
                        {formatNumber(get(data, 'percentages.subtotal_with_tax', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  py: 0,
                  color: 'white',
                  textAlign: 'right',
                  display: 'block'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '35px' : '45px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '6px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['16'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: label === 'Total' ? '#FFFFFF' : '#09234B'
                      }}>
                      {formatCurrency(get(data, 'values.subtotal', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: label === 'Total' ? '#E3EDF0' : '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['11']
                        }}>
                        {formatNumber(get(data, 'percentages.subtotal', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  py: 0,
                  color: label === 'Total' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(47, 74, 90, 0.6)',
                  textAlign: 'right',
                  display: 'block'
                }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            sx: { height: zoom >= 67 ? '40px' : '50px', borderBottom: '1px solid rgba(230, 230, 230, 1)' },
            hideIndicator: true,
            cols: [
              {
                label: (
                  <>
                    <span
                      style={{
                        color: 'rgba(47, 74, 90, 0.6)',
                        fontSize: fontSizes['16'],
                        lineHeight: 'initial',
                        fontWeight: 600
                      }}>
                      {formatCurrency(get(data, 'values.lost_sales', 0), { maxFractionDigits: 2 })}
                    </span>
                  </>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '6px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['16'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(data, 'values.unfulfilled_refunds', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['11']
                        }}>
                        {formatNumber(get(data, 'percentages.unfulfilled_refunds', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', px: '10px', py: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '6px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['16'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: `${get(data, 'values.tax_host', 0) > 0 ? 'rgba(15, 120, 60, 1)' : 'rgba(241, 84, 18, 1)'}`
                      }}>
                      {get(data, 'values.tax_host', 0) > 0 && '+'}
                      {formatCurrency(get(data, 'values.tax_host', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['11']
                        }}>
                        {formatNumber(get(data, 'percentages.tax_host', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { mt: '15px', height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: (
                  <>
                    <span
                      style={{
                        color: 'rgba(47, 74, 90, 0.6)',
                        fontSize: fontSizes['16'],
                        lineHeight: 'initial',
                        fontWeight: 600
                      }}>
                      {formatCurrency(get(data, 'values.tax_witheld', 0), { maxFractionDigits: 2 })}
                    </span>
                  </>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: (
                  <>
                    <span
                      style={{
                        color: 'rgba(47, 74, 90, 0.6)',
                        fontSize: fontSizes['16'],
                        lineHeight: 'initial',
                        fontWeight: 600
                      }}>
                      {formatCurrency(get(data, 'values.tax_backup_witheld', 0), { maxFractionDigits: 2 })}
                    </span>
                  </>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: showForPlatforms([PLATFORM.UBER_EATS], label) ? 'block' : 'none' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '6px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['16'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(data, 'values.commission', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['11']
                        }}>
                        {formatNumber(get(data, 'percentages.commission', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '6px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['16'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(15, 120, 60, 1)'
                      }}>
                      +{formatCurrency(get(data, 'values.restaurant_delivery_charge', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['11']
                        }}>
                        {formatNumber(get(data, 'percentages.restaurant_delivery_charge', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: showForPlatforms([PLATFORM.GRUBHUB, PLATFORM.EZ_CATER, PLATFORM.OLO], label) ? 'block' : 'none' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '6px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['16'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(data, 'values.marketing', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['11']
                        }}>
                        {formatNumber(get(data, 'percentages.marketing', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          },
          {
            hide: !oloExists || get(breakdownData, 'values.loyalty', 0) === 0,
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row-reverse',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '5px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['14'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(data, 'values.loyalty', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  textAlign: 'right',
                  display: showForPlatforms([PLATFORM.OLO], label) ? 'block' : 'none'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row-reverse',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '5px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['14'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(data, 'values.marketing_ad', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  textAlign: 'right',
                  display: 'block'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row-reverse',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '5px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['14'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(data, 'values.marketing_promo', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  textAlign: 'right',
                  display: 'block'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row-reverse',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '5px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['14'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(47, 74, 90, 0.6)'
                      }}>
                      {formatCurrency(Math.abs(get(data, 'values.marketing_fee', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  textAlign: 'right',
                  display: showForPlatforms([PLATFORM.DOORDASH], label) ? 'block' : 'none'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row-reverse',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '5px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['14'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(47, 74, 90, 0.6)'
                      }}>
                      {formatCurrency(Math.abs(get(data, 'values.merchant_funded_discount', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  textAlign: 'right',
                  display: showForPlatforms([PLATFORM.DOORDASH, PLATFORM.UBER_EATS], label) ? 'block' : 'none'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row-reverse',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '5px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['14'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(47, 74, 90, 0.6)'
                      }}>
                      {formatCurrency(Math.abs(get(data, 'values.third_party_funded_discount', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  textAlign: 'right',
                  display: showForPlatforms([PLATFORM.DOORDASH, PLATFORM.UBER_EATS], label) ? 'block' : 'none'
                }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '40px' : '50px', borderBottom: '1px solid rgba(230, 230, 230, 1)' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '6px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['16'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(data, 'values.chargeback', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['11']
                        }}>
                        {formatNumber(get(data, 'percentages.chargeback', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '6px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['16'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(15, 120, 60, 1)'
                      }}>
                      +{formatCurrency(get(data, 'values.reversal', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['11']
                        }}>
                        {formatNumber(get(data, 'percentages.reversal', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '6px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['16'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: otherTotal > 0 ? 'rgba(15, 120, 60, 1)' : 'rgba(241, 84, 18, 1)'
                      }}>
                      {formatCurrency(otherTotal, { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['11']
                        }}>
                        {formatNumber(
                          get(data, 'values.subtotal_with_tax', 0) === 0 ? 0 : Math.abs((otherTotal * 100) / get(data, 'values.subtotal_with_tax', 0)),
                          { maxFractionDigits: 2 }
                        )}
                        %
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { mt: '15px', height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: '+' + formatCurrency(get(data, 'values.customer_tip', 0), { maxFractionDigits: 2 }),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  textAlign: 'right',
                  display: 'block',
                  color: 'rgba(14, 140, 67, 1)',
                  fontSize: fontSizes['14']
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: '+' + formatCurrency(get(data, 'values.restaurant_fees', 0), { maxFractionDigits: 2 }),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  textAlign: 'right',
                  display: 'block',
                  color: 'rgba(14, 140, 67, 1)',
                  fontSize: fontSizes['14']
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row-reverse',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '5px'
                    }}>
                    <span>
                      {(get(data, 'values.miscellaneous', 0) >= 0 ? '+' : '-') +
                        formatCurrency(Math.abs(get(data, 'values.miscellaneous', 0)), { maxFractionDigits: 2 })}
                    </span>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  textAlign: 'right',
                  display: 'block',
                  color: get(data, 'values.miscellaneous', 0) >= 0 ? 'rgba(14, 140, 67, 1)' : 'rgba(241, 84, 18, 1)',
                  fontSize: fontSizes['14']
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row-reverse',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '5px'
                    }}>
                    <span>
                      {(get(data, 'values.unaccounted', 0) >= 0 ? '+' : '-') +
                        formatCurrency(Math.abs(get(data, 'values.unaccounted', 0)), { maxFractionDigits: 2 })}
                    </span>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  textAlign: 'right',
                  display: 'block',
                  color: get(data, 'values.unaccounted', 0) >= 0 ? 'rgba(14, 140, 67, 1)' : 'rgba(241, 84, 18, 1)',
                  fontSize: fontSizes['14']
                }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ bgcolor: label === 'Total' ? 'rgba(47, 74, 90, 1)' : '#FFFFFF', mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '6px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['16'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: label === 'Total' ? '#FFFFFF' : '#09234B'
                      }}>
                      {formatCurrency(get(data, 'values.total_payment', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{ bgcolor: '#E3EDF0', color: '#196E82', p: '4px 7px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['11'] }}>
                        {formatNumber(get(data, 'percentages.total_payment', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '6px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['16'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(data, 'values.cogs', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['11']
                        }}>
                        {formatNumber(get(data, 'percentages.cogs', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ bgcolor: label === 'Total' ? 'rgba(47, 74, 90, 1)' : '#FFFFFF', mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '6px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['16'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: label === 'Total' ? '#FFFFFF' : '#09234B'
                      }}>
                      {formatCurrency(get(data, 'values.total_payment', 0) - get(data, 'values.cogs', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{ bgcolor: '#E3EDF0', color: '#196E82', p: '4px 7px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['11'] }}>
                        {formatNumber(get(data, 'percentages.total_payment', 0) - get(data, 'percentages.cogs', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          }
        ]}
      />
    </Box>
  )
}

export default BreakdownByPlatform
