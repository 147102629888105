import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

// Chargebacks
const Chargebacks = lazy(() => import('src/pages/members/Chargebacks/Chargebacks'))
const ChargebacksTransactions = lazy(() => import('src/pages/members/ChargebacksTransactions/ChargebacksTransactions'))
const ChargebacksHistoryStoreView = lazy(() => import('src/pages/members/HistoryByStore/pages/ChargebacksHistoryStoreView/ChargebacksHistoryStoreView'))

const ChargebacksDetails = lazy(() => import('src/pages/members/ChargebacksDetails/ChargebacksDetails'))
const IssueDiscovery = lazy(() => import('src/pages/members/IssueDiscovery/IssueDiscovery'))
const IssueDiscoveryWithFilter = lazy(() => import('src/pages/members/IssueDiscoveryWithFilter/IssueDiscoveryWithFilter'))
const ChargebackDisputeConfigs = lazy(() => import('src/pages/members/ChargebackDisputeConfigs/ChargebackDisputeConfigs'))
const ChargebackConfigs = lazy(() => import('src/pages/members/ChargebackConfigs/ChargebackConfigs'))
const Errors = lazy(() => import('src/pages/members/Errors/Errors'))
const InAccurateOrders = lazy(() => import('src/pages/members/InAccurateOrders/InAccurateOrders'))

const MissedOrders = lazy(() => import('src/pages/members/MissedOrders/MissedOrders'))
const CancelledOrders = lazy(() => import('src/pages/members/CancelledOrders/CancelledOrders'))

export const existingRoutes = [
  { path: 'chargebacks', element: <Chargebacks tabValue="overview" /> },
  {
    path: 'chargebacks/overview',
    element: <Chargebacks tabValue="overview" />
  },
  { path: 'chargebacks/transactions', element: <ChargebacksTransactions /> },
  {
    path: 'chargebacks/payouts',
    element: (
      <Navigate
        replace
        to="/chargebacks/transactions"
      />
    )
  },
  {
    path: 'chargebacks/stores/view',
    element: <ChargebacksHistoryStoreView />
  },
  {
    path: 'chargebacks/matrix',
    element: <ChargebacksDetails />
  },
  {
    path: 'errors/issue-discovery/:tab/:filter',
    element: <IssueDiscovery />
  },
  {
    path: 'errors/issue-discovery/:tab/:filter/:filterName',
    element: <IssueDiscoveryWithFilter />
  },
  {
    path: 'issue-discovery/:tab/:filter',
    element: (
      <Navigate
        to="/errors/issue-discovery/discovery/by-reasons"
        replace
      />
    )
  },
  {
    path: 'issue-discovery/:tab/:filter/:filterName',
    element: (
      <Navigate
        to="/errors/issue-discovery/discovery/by-reasons"
        replace
      />
    )
  },
  {
    path: 'configurations/chargebacks/categories',
    element: <ChargebackDisputeConfigs />
  },
  {
    path: 'configurations/chargebacks',
    element: <ChargebackConfigs />
  },
  {
    path: 'errors',
    element: <Navigate to={`/recover/order-accuracy/overview${window.location.search}`} />
  },
  { path: 'errors/overview', element: <Errors /> },
  { path: 'errors/inaccurate-orders', element: <InAccurateOrders /> },
  { path: 'errors/inaccurate-orders/:filter', element: <InAccurateOrders /> },
  { path: 'errors/missed-orders', element: <MissedOrders /> },
  { path: 'errors/missed-orders/:filter', element: <MissedOrders /> },
  { path: 'errors/cancelled-orders', element: <CancelledOrders /> },
  { path: 'errors/cancelled-orders/:filter', element: <CancelledOrders /> }
]

export const moduleNamedRoutes = [
  {
    path: 'recover',
    element: <Chargebacks tabValue="overview" />,
    children: [
      { index: true, element: <Chargebacks tabValue="overview" /> },
      { path: 'overview', element: <Chargebacks tabValue="overview" /> },
      { path: 'transactions', element: <ChargebacksTransactions /> },
      {
        path: 'payouts',
        element: (
          <Navigate
            replace
            to="/recover/transactions"
          />
        )
      },
      {
        path: 'stores-view',
        element: <ChargebacksHistoryStoreView />
      },
      {
        path: 'matrix',
        element: <ChargebacksDetails />
      },
      {
        path: 'issue-discovery/:tab/:filter',
        element: <IssueDiscovery />
      },
      {
        path: 'issue-discovery/:tab/:filter/:filterName',
        element: <IssueDiscoveryWithFilter />
      },
      {
        path: 'issue-discovery/:tab/:filter',
        element: (
          <Navigate
            to="/recover/issue-discovery/discovery/by-reasons"
            replace
          />
        )
      },
      {
        path: 'issue-discovery/:tab/:filter/:filterName',
        element: (
          <Navigate
            to="/recover/issue-discovery/discovery/by-reasons"
            replace
          />
        )
      },
      {
        path: 'configs',
        children: [
          {
            index: true,
            element: (
              <Navigate
                to="/recover/configs/locations"
                replace
              />
            )
          },
          {
            path: 'locations',
            element: <ChargebackConfigs />
          },
          {
            path: 'categories',
            element: <ChargebackDisputeConfigs />
          }
        ]
      },
      {
        path: 'order-accuracy',
        children: [
          {
            path: 'overview',
            element: <Errors />
          },
          {
            path: 'cancelled',
            element: <CancelledOrders />
          },
          {
            path: 'inaccurate',
            element: <InAccurateOrders />
          },
          {
            path: 'missed',
            element: <MissedOrders />
          },
          {
            path: 'missed/:filter',
            element: <MissedOrders />
          },
          {
            path: 'cancelled/:filter',
            element: <CancelledOrders />
          }
        ]
      }
    ]
  }
]

export const recoverRoutes = [...existingRoutes, ...moduleNamedRoutes]
