import { Typo } from '@LoopKitchen/loop-ui'
import { Info } from '@mui/icons-material'
import { Box } from '@mui/material'
import ColoredTooltip from 'src/components/Tooltip/ColoredTooltip'
import DataComp from 'src/pages/members/Finance/components/DataComp'
import { indicatorColor, rowFlex } from 'src/pages/members/Finance/utils/constants'
import { usePayoutsBreakdownDataContext } from 'src/pages/members/Finance/utils/context/PayoutsBreakdownDataContext'
import { OrderBreakdownResponse, PLATFORM } from 'src/services/openApiV2'
import { Modules, platformNames } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import tooltips from '../IncomeStatementTooltips'

const BreakdownIndex = ({ label, data, oloExists }: { label: string; data: OrderBreakdownResponse; oloExists: boolean }) => {
  const { fontSizes, zoom } = usePayoutsBreakdownDataContext()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '0 5px',
        overflowX: 'hidden'
      }}>
      <Box
        sx={{
          borderRadius: '8px',
          height: zoom >= 67 ? '50px' : '60px',
          marginBottom: '10px',
          border: '1px solid #E6E6E6',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typo
          fs={fontSizes['14']}
          fw={400}
          sx={{ display: 'flex', justifyContent: 'center' }}>
          {label}
        </Typo>
      </Box>

      <DataComp
        sx={{ px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '35px' : '45px' },
            cols: [
              {
                label: 'Sales Incl. Tax',
                flex: rowFlex[2],
                sx: {
                  py: 0,
                  fontSize: fontSizes['14'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.sales_incl_tax
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '35px' : '45px' },
            cols: [
              {
                label: 'Sales Excl. Tax',
                flex: rowFlex[2],
                sx: {
                  py: 0,
                  fontSize: fontSizes['13'],
                  fontWeight: 400,
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.sales_excl_tax
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: Modules.LostSales,
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontSize: fontSizes['13'],
                  fontWeight: 400,
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.lost_sales
              }
            ]
          },
          {
            indicatorColor: indicatorColor.refunds,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: Modules.UnfulfilledRefunds,
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.unfulfilled_refunds
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', px: '10px', py: '10px' }}
        rows={[
          {
            hideIndicator: true,
            indicatorColor: indicatorColor.taxes,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: 'Taxes',
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.taxes,
                rightComp: get(data, 'values.tax_backup_witheld', 0) > 0 && (
                  <ColoredTooltip
                    title="Potential high tax-related withholdings by third-party provider. It is recommended to update taxpayer information with the third-party provider to resolve the issue."
                    tooltipSx={{ fontSize: fontSizes['12'] }}>
                    <Info sx={{ fontSize: 20, color: '#E52E2E' }} />
                  </ColoredTooltip>
                )
              }
            ]
          },
          {
            sx: { mt: '15px', height: zoom >= 67 ? '30px' : '40px' },
            hideIndicator: true,
            cols: [
              {
                label: 'Taxes Withheld',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontSize: fontSizes['13'],
                  fontWeight: 400,
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.taxes_withheld
              }
            ]
          },
          {
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            hideIndicator: true,
            cols: [
              {
                label: 'Taxes Backup Withheld',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontSize: fontSizes['13'],
                  fontWeight: 400,
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.tax_backup_withheld
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            indicatorColor: indicatorColor.commissions,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: 'Commissions',
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.commissions
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            indicatorColor: indicatorColor.restaurant_delivery_charge,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: 'Rest. Delivery Charge',
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.restaurant_delivery_charge
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            indicatorColor: indicatorColor.marketing,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: 'Marketing',
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.marketing
              }
            ]
          },
          {
            hideIndicator: true,
            indicatorColor: indicatorColor.loyalty,
            sx: { borderTop: '1px solid rgba(230, 230, 230, 1)', height: zoom >= 67 ? '40px' : '50px', mt: '8px', pt: '8px' },
            hide: !oloExists || get(data, 'values.loyalty', 0) === 0,
            cols: [
              {
                label: 'Loyalty',
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                flex: rowFlex[0],

                tooltip: tooltips.loyalty
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: 'Ad Spend',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.marketing_ad_spend
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: 'Promo Spend',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.marketing_promo_spend
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: `${platformNames[PLATFORM.DOORDASH]} Marketing Fee`,
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.marketing_fee
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: 'Merchant Funded Discount',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.merchant_funded_discount
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: '3P Funded Discount',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.third_party_funded_discount
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            indicatorColor: indicatorColor.refunds,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: Modules.Chargebacks,
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.chargebacks
              }
            ]
          },
          {
            hideIndicator: true,
            indicatorColor: indicatorColor.reversals,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: Modules.WonDisputes,
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                flex: rowFlex[0],

                tooltip: tooltips.won_disputes
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            indicatorColor: indicatorColor.unaccounted,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: 'Others',
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { mt: '15px', height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: 'Customer Tip',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.customer_tips
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: 'Restaurant Fees',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.restaurant_fees
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: 'Miscellaneous',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.miscellaneous
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: 'Unaccounted',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.unaccounted
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: 'Net Payout',
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: 'Cost of Goods Sold',
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                },
                tooltip: tooltips.cost_of_goods_sold
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: 'Net Margin',
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13'],
                  width: '100%',
                  '& span': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }
                }
              }
            ]
          }
        ]}
      />
    </Box>
  )
}

export default BreakdownIndex
