import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { collection, deleteDoc, doc, getDoc, getDocs, initializeFirestore, query, where } from 'firebase/firestore'

import { getStorage } from 'firebase/storage'
import { WizardType } from 'src/context/WizardContext'
import { SupersetLinkType } from 'src/types/supersetLink'
import { AccessLevelEnum, BASE_URL } from 'src/utils/config/config'
import { AccountingProviderName } from './openApiV2'

export const firebaseConfig = {
  apiKey: 'AIzaSyAPstTsXFKtlRyC5XnfE71A1PBwQGPhuNY',
  authDomain: BASE_URL,
  projectId: 'arboreal-vision-339901',
  storageBucket: 'arboreal-vision-339901.appspot.com',
  messagingSenderId: '983150008719',
  appId: '1:983150008719:web:094b902dc0e51ab27dd3fa'
  // measurementId: 'G-G71XJ4JH01'
}

export const app = initializeApp(firebaseConfig)
export const db = initializeFirestore(app, { experimentalForceLongPolling: true })
export const firebaseDb = db // for backward compatibility
export const auth = getAuth(app)

export const bookkeepingIntegrationsCollectionIds = Object.values(AccountingProviderName).map((key) => `${key}_integration_details`)

export const allowedCollectionsWithoutId = ['superset_links', 'route_categories', 'google_studios_links', 'subchain_modules']
export const allowedCollectionsWithDocumentId = [
  'org_config_2',
  'panel_settings',
  'notes',
  'saved_searches',
  'store_details',
  'slack_teams_info',
  'users'
].concat(bookkeepingIntegrationsCollectionIds)

export const fetchFirebaseData = async (collectionName: string, documentId?: string): Promise<any> => {
  const firebaseCall = async (): Promise<any> => {
    if (allowedCollectionsWithDocumentId.includes(collectionName) && documentId) {
      const res = await getDoc(doc(db, collectionName, documentId))
      return res.data()
    }

    if (allowedCollectionsWithoutId.includes(collectionName)) {
      const res = await getDocs(collection(db, collectionName))
      const documents = res.docs.map((doc) => doc.data())
      return documents
    }
  }

  return await firebaseCall()
}

export const fetchRouteCategories = async (): Promise<any> => {
  try {
    const response = await fetchFirebaseData('route_categories')
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchUserOrgAndRole = async (id: string): Promise<any> => {
  try {
    const response = await fetchFirebaseData('users', id)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export const storage = getStorage(app)

export const fetchCredentials = async (org): Promise<any> => {
  try {
    const Doc = query(collection(db, 'credentials_v2'), where('chain', '==', org))
    const querySnapshot = await getDocs(Doc)
    return querySnapshot
  } catch (error) {
    return Promise.reject(error)
  }
}

export const savePanelSettings = async (_org, _panelSettings): Promise<void> => {
  try {
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchPanelSettings = async (org): Promise<any> => {
  try {
    const querySnapshot = await getDoc(doc(db, 'panel_settings', org))
    return querySnapshot.data()
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchPanels = async (): Promise<any> => {
  try {
    const querySnapshot = await getDocs(query(collection(db, 'panels')))

    const documents = querySnapshot.docs.map((doc) => doc.data())
    return documents
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchGoogleStudioLinks = async (): Promise<any> => {
  try {
    const response = await fetchFirebaseData('google_studios_links')
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchSupersetLinks = async (): Promise<SupersetLinkType[]> => {
  try {
    const response: SupersetLinkType[] = await fetchFirebaseData('superset_links')
    return response
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchOrgConfig = async (org: string, access_level: string | string[]): Promise<any> => {
  const admin = AccessLevelEnum.BUSINESS_ADMIN
  try {
    const response = await fetchFirebaseData('org_config_2', org.trim())
    let tempAcessLevel = []

    // this will check if access level is string (old version) type
    if (access_level) {
      if (typeof access_level === 'string') {
        tempAcessLevel = [access_level]
      } else tempAcessLevel = access_level
    } else {
      tempAcessLevel = [admin]
    }

    // this will join routes for multiple access levels
    const tempAccessLevels: string[] = Object.keys(response.access_levels || {})
    let routes: string[] = []

    // this will run the joining of routes if user is not business-admin
    if (!tempAccessLevels.includes(admin)) {
      tempAcessLevel.forEach((item) => {
        routes = routes.concat(response.access_levels ? response.access_levels[item]?.routes || [] : [])
      })
    } else {
      routes = response.access_levels ? response.access_levels[admin]?.routes || [] : []
    }
    return {
      ...response,
      navConfig: routes,
      filterConfig: response.filterConfig || {
        am_name: false,
        vb_name: false,
        vb_platform: true,
        b_name: true
      }
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const setAllAccessLevels = async (): Promise<AccessLevelType[]> => {
  try {
    const querySnapshot = await getDocs(collection(db, 'access_levels'))
    const documents = querySnapshot.docs.map((doc) => {
      const data = doc.data()
      return {
        name: data?.name,
        label: data?.label,
        is_custom: data?.is_custom
      }
    })
    localStorage.setItem('allAccessLevels', JSON.stringify(documents))
    return documents
  } catch (err) {
    return Promise.reject(err)
  }
}
type AccessLevelType = { name: string; label: string; is_custom?: boolean }
export function getAllAccessLevels(): AccessLevelType[]
export function getAllAccessLevels(name: string): AccessLevelType
export function getAllAccessLevels(name?: string): any {
  let result: AccessLevelType[] = []
  try {
    result = JSON.parse(localStorage.getItem('allAccessLevels') || '[]')
  } catch (error) {
    console.error('Error parsing access levels from local storage:', error)
  }

  if (name) {
    return result.find((item) => item.name === name)
  }
  return result
}

export const saveSearch = async (_savedSearch): Promise<void> => {
  try {
  } catch (error) {
    console.error('Failed to save search:', error)
  }
}

export const fetchSavedSearches = async (user): Promise<any> => {
  try {
    const querySnapshot = await getDocs(query(collection(db, 'saved_searches'), where('createdBy.org', '==', user.org)))
    const documents = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id
    }))
    const result = (documents as any).filter((document) => document.createdBy.uid === user.uid)
    result.sort((a, b) => b.updatedAt - a.updatedAt)
    return result
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteSavedSearch = async (id: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, 'saved_searches', id))
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateSavedSearch = async (_id, _savedSearch): Promise<void> => {
  try {
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getStoreDetails = async (org: string): Promise<any> => {
  try {
    const querySnapshot = await getDocs(query(collection(db, 'store_details'), where('chain', '==', org)))
    const result = querySnapshot.docs.map((doc) => doc.data())
    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

export const isSlackIntegrated = async (org: string): Promise<boolean> => {
  try {
    const querySnapshot = await getDocs(query(collection(db, 'slack_teams_info'), where('chain', '==', org)))

    return !querySnapshot.empty
  } catch (err) {
    return Promise.reject(err)
  }
}

export function logTimeDifference(end: number, start: number, title?: string): void {
  const diff = end - start
  if (diff > 400) console.log(`${title} - ${parseFloat((diff / 1000).toString()).toFixed(2)}s`)
}
export let labelList = []
export let timeList = []
export function appendLatestTime(label: string): void {
  const end = Date.now()
  timeList.push(end)
  labelList.push(label)
  if (timeList.length > 1) {
    logTimeDifference(end, timeList[timeList.length - 2], `${labelList[labelList.length - 2]} - ${label}`)
    logTimeDifference(timeList[timeList.length - 1], timeList[0], 'TOTAL')
  }
  // if (timeList.length > 10) console.log({timeList, labelList})
}

export function clearList(): void {
  labelList = []
  timeList = []
}

export function setTokenExpireToStorage(expirationTime: string): void {
  sessionStorage.setItem('token_expiration_time', expirationTime)
}

export function getTokenExpireFromStorage(): string | null {
  return sessionStorage.getItem('token_expiration_time')
}

export function setTokenCreationToStorage(creationTime: string): void {
  sessionStorage.setItem('token_creation_time', creationTime)
}

export function getTokenCreationFromStorage(): string | null {
  return sessionStorage.getItem('token_creation_time')
}

export async function getWizardRoutes(wizardUid: string[]): Promise<WizardType[]> {
  if (!wizardUid.length) return []
  const res = await getDocs(query(collection(db, 'wizard_config'), where('key', 'in', wizardUid)))
  const data = res.docs.map((e) => e.data())
  return data as WizardType[]
}
