type Metric = {
  name: string;
  help: string;
  type: 'gauge' | 'counter' | 'histogram';
  labels?: Record<string, string>;
  value?: any;
  timestamp?: string
  buckets?: number[]
};


const appErrorMetrics: Record<string, Metric> = {
  errorCounter: {
    name: 'app_error_total',
    help: 'Total number of errors',
    type: 'counter'
  },
  errorGeneralHistogram: {
    name: 'app_error_general_histogram',
    help: 'Histogram of errors',
    buckets: [0.1, 0.5, 1, 2, 5, 10],
    type: 'histogram'
  },
  errorComponent: {
    name: 'app_error_component_total',
    help: 'Total number of component errors',
    type: 'counter'
  }
}


class MetricsService {
  private static readonly METRICS_URL = 'https://metrics.tryloop.ai'

  private static instance: MetricsService

  private metricsQueue: Array<Metric> = []
  private flushTimeoutId: NodeJS.Timeout | null = null

  private readonly taskPriority = {
    flush: { timeout: 1000 } as IdleRequestOptions,
    track: { timeout: 2000 } as IdleRequestOptions
  }

  private readonly debounceTimers: Record<string, NodeJS.Timeout> = {}
  private readonly debounceDelay = 1000 // 1 second

  private readonly sampleRates: Record<string, number> = {
    user_behavior: 0.1, // Track 10% of behavior events
    performance: 1.0, // Track 100% of performance events
    error: 1.0, // Track 100% of errors
    default: 1.0 // Default 20% sampling
  }

  private readonly timeout = 1000 // 3 seconds timeout
  private readonly maxRetries = 2
  private readonly baseRetryDelay = 1000 // Base delay of 1 second
  private readonly maxRetryDelay = 10000 // Maximum delay of 10 seconds

  private readonly networkConfig = {
    slow: { batchSize: 150, flushInterval: 45000 }, // 45s for slow
    medium: { batchSize: 100, flushInterval: 30000 }, // 30s for medium
    fast: { batchSize: 50, flushInterval: 15000 } // 15s for fast
  }

  private currentNetworkConfig = this.networkConfig.medium

  private constructor(private readonly url: string) {
    this.url = url
    this.startNetworkMonitoring()
    this.startPeriodicFlush()
  }

  public sanitizeJSON(input: Record<string, any> | string, type: 'header' | 'body' | 'query'): Record<string, any> | string {
    if (type === 'header' || type === 'query') {
      // TODO: Implement this
      // Remove auth token from headers
      // Remove any other sensitive information from headers
      return input
    }

    if (typeof input === 'string') {
      return input
    }

    return typeof input === 'object' && input !== null ? input : {}
  }

  public static getInstance(): MetricsService {
    if (!MetricsService.instance) {
      MetricsService.instance = new MetricsService(MetricsService.METRICS_URL)
    }
    return MetricsService.instance
  }

  private async fetchWithTimeout(url: string, options: RequestInit): Promise<Response> {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), this.timeout)

    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal
      })
      return response
    } finally {
      clearTimeout(timeoutId)
    }
  }

  private async retryOperation(operation: () => Promise<any>): Promise<void> {
    for (let attempt = 1; attempt <= this.maxRetries; attempt++) {
      try {
        await operation()
        return
      } catch (error) {
        console.error(`Metrics tracking failed (attempt ${attempt}/${this.maxRetries}):`, error)

        if (attempt === this.maxRetries) {
          // Drop the metric after max retries to prevent infinite loops
          console.warn('Max retries reached, dropping metric')
          return
        }

        // Exponential backoff with jitter
        const delay = Math.min(this.maxRetryDelay, this.baseRetryDelay * Math.pow(2, attempt - 1) * (0.5 + Math.random() * 0.5))
        await new Promise((resolve) => setTimeout(resolve, delay))
      }
    }
  }

  private startNetworkMonitoring() {
    if (typeof navigator === 'undefined' || !('connection' in navigator)) return

    const connection = (navigator as any).connection
    if (connection) {
      this.updateNetworkConfig(connection)
      connection.addEventListener('change', () => this.updateNetworkConfig(connection))
    }
  }

  private updateNetworkConfig(connection: any) {
    const effectiveType = connection.effectiveType || 'medium'
    const downlink = connection.downlink || 2

    if (downlink <= 1 || effectiveType === '2g') {
      this.currentNetworkConfig = this.networkConfig.slow
    } else if (downlink <= 5 || effectiveType === '3g') {
      this.currentNetworkConfig = this.networkConfig.medium
    } else {
      this.currentNetworkConfig = this.networkConfig.fast
    }

    // Update flush interval if already running
    if (this.flushTimeoutId) {
      clearInterval(this.flushTimeoutId)
      this.startPeriodicFlush()
    }
  }

  private shouldSampleEvent(category: string): boolean {
    const sampleRate = this.sampleRates[category] ?? this.sampleRates.default
    return Math.random() < sampleRate
  }

  private async trackMetric(category: string, metric: Metric, value: number = 1) {
    // Apply sampling
    if (!this.shouldSampleEvent(category)) {
      return
    }

    if (category === 'error') {
      metric.labels = {
        ...metric.labels, // Safely spread existing label names
        network_type: (navigator as any)?.connection?.effectiveType || 'unknown', // Add network_type
      };
    }


    if (this.metricsQueue.length >= this.currentNetworkConfig.batchSize) {
      console.warn('Metrics queue size limit reached, dropping oldest metrics')
      this.metricsQueue = this.metricsQueue.slice(-Math.floor(this.currentNetworkConfig.batchSize * 0.9))
    }

    if (typeof window !== 'undefined' && 'requestIdleCallback' in window) {
      window.requestIdleCallback(() => {
        this.addToQueue(category, metric, value)
      }, this.taskPriority.track)
    } else {
      setTimeout(() => {
        this.addToQueue(category, metric, value)
      }, 0)
    }
  }

  private addToQueue(category: string, metric: Metric, value: number) {
    metric.value = value;
    metric.timestamp = new Date().toISOString();
    this.metricsQueue.push(metric)
  }

  public async trackDebounced(category: string, metric: Metric, value: number = 1) {
    const key = `${category}-${metric}-${JSON.stringify(metric.labels)}`

    if (this.debounceTimers[key]) {
      clearTimeout(this.debounceTimers[key])
    }

    this.debounceTimers[key] = setTimeout(() => {
      this.trackMetric(category, metric, value)
      delete this.debounceTimers[key]
    }, this.debounceDelay)
  }

  private startPeriodicFlush() {
    if (typeof window !== 'undefined' && 'requestIdleCallback' in window) {
      const scheduleNextFlush = () => {
        window.requestIdleCallback(() => {
          this.flush()
            .catch((error) => console.error('Failed to flush metrics:', error))
            .finally(() => {
              this.flushTimeoutId = setTimeout(scheduleNextFlush, this.currentNetworkConfig.flushInterval)
            })
        }, this.taskPriority.flush)
      }

      this.flushTimeoutId = setTimeout(scheduleNextFlush, this.currentNetworkConfig.flushInterval)
    } else {
      this.flushTimeoutId = setInterval(() => {
        this.flush().catch((error) => console.error('Failed to flush metrics:', error))
      }, this.currentNetworkConfig.flushInterval)
    }
  }

  public async trackCritical(category: string, metric: Metric, value: number = 1) {
    await this.addToQueue(category, metric, value)
    if (this.metricsQueue.length >= Math.min(10, this.currentNetworkConfig.batchSize)) {
      await this.flush()
    }
  }

  private async flush(): Promise<void> {
    if (this.metricsQueue.length === 0) return

    // Process one metric at a time for now, but structure allows for future batching
    while (this.metricsQueue.length > 0) {
      const metric = this.metricsQueue.shift()!
      await this.collectMetric(metric)
    }
  }

  private async collectMetric(metric: Metric) {
    await this.retryOperation(async () => {
      try {
        const response = await this.fetchWithTimeout(`${this.url}/metric`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(metric)
        })

        if (!response.ok) {
          // Only retry on 5xx errors, drop metric on 4xx
          if (response.status >= 500) {
            throw new Error(`Server error: ${response.status}`)
          } else {
            console.warn(`Dropping metric due to ${response.status} response`)
            return
          }
        }
      } catch (error) {
        throw error // Let retryOperation handle the retry logic
      }
    })
  }

  // Status/Error Metrics
  public async trackError(
    errorType: string = 'unknown',
    errorCode: string | number = 'unknown', //1xx, 2xx, 3xx, 4xx, 5xx,
    module_name: string = 'unknown',
    userType: string = 'external', // internal => Loop user,  external = client(customers)
    errorMessage: string = 'unknown',
    duration: number = -1, // get the time to resolve the API (-1 if unknown)
    version: string = 'unknown', // application or API version
    pageUrl: string = 'unknown', // page path of the browsser from where the api is getting triggered
    apiUrl: string = 'unknown', // api endpoint, which is responsible to complete the transaction
    email: string = 'unknown', // email address of the user
    org: string = 'unknown', // organization of the user
    accessLevel: string = 'unknown',
    methodName: string = 'unknown'
  ) {
    const counterMetric = appErrorMetrics.errorCounter;
    // const histogramMetric = appErrorMetrics.errorGeneralHistogram;

    const labels = {
      error_type: errorType,
      error_code: errorCode?.toString(),
      module: module_name,
      user_type: userType,
      error_message: errorMessage,
      duration: duration !== -1 ? duration.toString() : 'unknown',
      version: version,
      page_url: pageUrl,
      api_url: apiUrl,
      email: email,
      org: org,
      access_level: accessLevel,
      method_name: methodName
    };

    counterMetric.labels = labels
    // histogramMetric.labels = labels

    await this.trackMetric('error', counterMetric)
    // await this.trackMetric('error', histogramMetric);
  }

  public async cleanup() {
    if (this.flushTimeoutId) {
      clearInterval(this.flushTimeoutId)
    }
    // Flush any remaining metrics
    await this.flush()
  }
}

// return a singleton instance of MetricsService
export const metricsServiceUsingMetricEndpoint = MetricsService.getInstance()

// Usage examples:
/*
metricsServiceUsingMetricEndpoint.trackApiError('GET', '/api/users', 500, 'NetworkError', 'UserModule')
*/
