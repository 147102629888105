import { ArrowUpward } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { defaultEmptyScreenStyles } from './EmptyScreenStyles'

const EmptyScreen = ({ NoDataImg, labels }) => {
  const handleScroll = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }
  return (
    <Box sx={{ ...defaultEmptyScreenStyles().mainWrapper, pt: '50px' }}>
      <Box sx={{ ...defaultEmptyScreenStyles().imageWrapper }}>
        <img
          src={NoDataImg}
          alt="no-data"
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
      <Box sx={{ ...defaultEmptyScreenStyles().textWrapper }}>
        <Typography
          variant="body1"
          sx={{ ...defaultEmptyScreenStyles().text1 }}>
          {labels.NO_DATA}
        </Typography>
        <Typography sx={{ ...defaultEmptyScreenStyles().text2 }}>{labels.TRY_FILTERS}</Typography>
      </Box>
      <Box sx={{ ...defaultEmptyScreenStyles().buttonWrapper }}>
        <Button
          sx={{
            ...defaultEmptyScreenStyles().button
          }}
          onClick={handleScroll}>
          <ArrowUpward fontSize="small" />
          {labels.GO_TO_FILTERS}
        </Button>
      </Box>
    </Box>
  )
}

export default EmptyScreen
