import { captureException, captureMessage } from '@sentry/react'
import { POST_HOG_BASE_URL } from 'src/assets/configs/globalConst'
import { metricsServiceUsingMetricEndpoint } from 'src/services/metrices/metricServiceExtended'
import { sendSlackNotification } from 'src/utils/api'
import { APP_VERSION } from 'src/utils/config/config'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { getSession } from 'src/utils/functions/localStorage'
import { getFrontendAlertChannel } from 'src/utils/functions/slackSupport'

const appVersion = APP_VERSION

// Function to test Sentry error logging
function triggerTestError() {
  try {
    // Simulate an error
    throw new Error('Test error for Sentry setup validation!')
  } catch (error) {
    // Capture the error using Sentry
    captureException(error)

    // Log to console for confirmation
    console.log('Test error sent to Sentry:', error)
  }
}

// Function to test Sentry message logging
function triggerTestMessage() {
  // Capture a test message using Sentry
  captureMessage('Test message for Sentry setup validation!')
  // Log to console for confirmation
  console.log('Test message sent to Sentry')
}

function triggerTestSlackError() {
  const email = getSession('data')?.email
  const org = getSession('data')?.org

  metricsServiceUsingMetricEndpoint.trackError(
    'GENERAL', "not_applicable", "unknown",
    process.env.NODE_ENV === 'production' ? 'external' : 'internal',
    "Test Slack Error",
    -1,
    appVersion,
    window.location.pathname,
    "not applicable",
    email,
    org,
    getAccessLevel(getSession('data')),
    "not applicable"
  )
  sendSlackNotification({
    message: `Test Message to Slack`,
    channel: getFrontendAlertChannel(),
    title: `urls:
    \`<${window.location.href}|${window.location.href}>\`
    \`<${POST_HOG_BASE_URL}/person/${email}#activeTab=sessionRecordings|${email}>\`
    \`<${POST_HOG_BASE_URL}/groups/0/${org}|${org}>\`
    \`this is coming from the test page "/test-internal"\
    `
  })
}

const TestPage = (): JSX.Element => {
  return (
    <div>
      <h1>Testing Module </h1>
      <button onClick={triggerTestError}>Trigger Test Error</button>
      <button onClick={triggerTestMessage}>Trigger Test Message</button>
      <button onClick={() => metricsServiceUsingMetricEndpoint.trackError('Test Error', 'TEST123', 'Test Module', 'internal','This is a test error message', 1234, appVersion, '/test-page', '/api/test-endpoint', 'TestOrg', 'Admin', 'testMethod')}>Track Test Error</button>
      <button onClick={() => metricsServiceUsingMetricEndpoint.trackError('Test API Error', 'TEST123', 'Test Module', 'internal','This is a test error message', 1234, appVersion, '/test-page', '/api/test-endpoint', 'TestOrg', 'Admin', 'testMethod')}>Track Test API Error</button>
      <button onClick={triggerTestSlackError}>Trigger slack errors as Internal Error</button>
      <button onClick={triggerTestSlackError}>Trigger slack errors as External Error</button>
    </div>
  )
}

export default TestPage
