import { Modules, filterNames } from 'src/utils/config/config'

export interface RouteMap {
  name: string
  path: string
  tags: string[]
  isChild?: boolean
  childRoutes?: RouteMap[]
}

const routeMap: RouteMap[] = [
  {
    name: 'Order Accuracy',
    path: '/recover/order-accuracy/overviewccuracy/overview',
    tags: ['order accuracy', 'orders', 'error overview'],
    childRoutes: [
      {
        name: 'Order accuracy kpi',
        path: '/recover/order-accuracy/overview?section=dashboard-kpi',
        tags: ['kpi', 'accuracy', 'order accuracy']
      },
      {
        name: 'Inaccurate Orders',
        path: '/recover/order-accuracy/inaccurate',
        tags: ['inaccurate orders']
      },
      {
        name: 'Missed Orders',
        path: '/errors/missed-orders',
        tags: ['missed orders', 'missing orders']
      },
      {
        name: 'Canceled orders',
        path: '/errors/cancelled-orders',
        tags: ['cancelled orders']
      },
      {
        name: 'Issue Discovery',
        path: '/errors/issue-discovery/discovery/by-reasons',
        tags: ['issue discovery']
      },
      {
        name: 'Paused stores',
        path: '/errors/issue-discovery/stores-to-fix/by-kpi?category=audit&subcategory=Store%20Paused',
        tags: ['issue discovery', 'store paused']
      },
      {
        name: 'Business hour mismatch',
        path: '/errors/issue-discovery/stores-to-fix/by-kpi?category=audit&subcategory=Business%20Hours%20Mismatch',
        tags: ['issue discovery', 'business hour mismatch']
      },
      {
        name: 'Deactivated stores',
        path: '/errors/issue-discovery/stores-to-fix/by-kpi?category=audit&subcategory=Store%20Deactivated',
        tags: ['issue discovery', 'deactivated stores']
      },
      {
        name: 'Stores with more than 30% revenue lost',
        path: '/errors/issue-discovery/stores-to-fix/by-kpi?category=operations&subcategory=%3E30%%20of%20the%20revenue%20lost%20in%20error%20charges',
        tags: ['issue discovery', 'stores with more than 30% revenue lost']
      },
      {
        name: 'Stores with more than 50% cancellation',
        path: '/errors/issue-discovery/stores-to-fix/by-kpi?category=operations&subcategory=>50%%20of%20the%20store%20errors%20are%20cancellations',
        tags: ['issue discovery', 'stores with more than 50% cancellations']
      },
      {
        name: 'Stores with more than 50% inaccurate orders',
        path: '/errors/issue-discovery/stores-to-fix/by-kpi?category=operations&subcategory=%3E50%%20of%20the%20store%20errors%20are%20inaccurate%20orders',
        tags: ['issue discovery', 'stores with more than 50% inaccurate orders']
      },
      {
        name: 'Stores with atleast 1 missed orders',
        path: '/errors/issue-discovery/stores-to-fix/by-kpi?category=operations&subcategory=All%20stores%20which%20missed%20orders',
        tags: ['issue discovery', 'stores with atleast 1 missed orders']
      },
      {
        name: 'Stores with more than 10% error rate',
        path: '/errors/issue-discovery/stores-to-fix/by-kpi?category=operations&subcategory=Stores%20with%20>10%%20Error%20Rate',
        tags: ['issue discovery', 'stores with more than 10% error rate']
      },
      {
        name: 'Stores with more than 50% cancellation due to store closures',
        path: '/errors/issue-discovery/stores-to-fix/by-kpi?category=operations&subcategory=>50%%20of%20the%20store%20errros%20are%20due%20to%20store%20closure',
        tags: ['issue discovery', 'stores with more than 50% cancellation due to store closures']
      },
      {
        name: 'Active stores with 0 sales',
        path: '/errors/issue-discovery/stores-to-fix/by-kpi?category=performance&subcategory=Active%20Virtual%20brands%20with%20zero%20sales',
        tags: ['issue discovery', 'Active stores with 0 sales']
      },
      {
        name: 'Stores with 0 sales',
        path: '/errors/issue-discovery/stores-to-fix/by-kpi?category=performance&subcategory=Virtual%20brands%20with%20zero%20sales',
        tags: ['issue discovery', 'stores with 0 sales']
      },
      {
        name: 'Stores with more than 50% or $1000 dip in sales',
        path: '/errors/issue-discovery/stores-to-fix/by-kpi?category=performance&subcategory=Virtual%20brands%20with%2050%%20dip',
        tags: ['issue discovery', 'stores with more than 50% or $1000 dip in sales']
      }
    ]
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    tags: ['dashboard', 'home', 'kpi'],
    childRoutes: [
      {
        name: 'Kpi',
        path: '/dashboard?section=dashboard',
        tags: ['dashboard', 'kpi']
      },
      {
        name: 'Sales overview',
        path: '/dashboard?section=dashboard-sales-overview',
        tags: ['dashboard', 'sales overview']
      },
      {
        name: 'Error overview',
        path: '/dashboard?section=dashboard-error-overview',
        tags: ['dashboard', 'error overview']
      }
    ]
  },
  {
    name: Modules.StoreAvailability,
    path: '/stores/availability',
    tags: ['stores', Modules.StoreAvailability.toLowerCase(), 'inactive stores', 'store restore history', 'history by store', 'store view'],
    childRoutes: [
      {
        name: 'Overview',
        path: '/stores/availability/overview',
        tags: [Modules.StoreAvailability.toLowerCase(), 'overview']
      },
      {
        name: 'Inactive Stores',
        path: '/stores/availability/inactive/stores',
        tags: [Modules.StoreAvailability.toLowerCase(), 'inactive stores']
      },
      {
        name: 'History by Store',
        path: '/stores/availability/store/view',
        tags: [Modules.StoreAvailability.toLowerCase(), 'history by store', 'store view']
      }
    ]
  },
  {
    name: '3P Invoices',
    path: '/invoice',
    tags: [`Generate invoices for ${filterNames.b_name}`, 'invoice', 'generate invoice']
  },
  {
    name: 'All Orders',
    path: '/base/all-orders/overview',
    tags: ['all orders', 'orders', 'list order']
  },
  {
    name: 'Item Performance',
    path: '/item-performance',
    tags: ['item', 'item performance', 'performance']
  },
  {
    name: 'Customer reviews',
    path: '/reviews/summary',
    tags: ['reviews', 'review summary', 'summary']
  },
  {
    name: 'Image Manager',
    path: '/missing-image-upload',
    tags: ['missing image upload']
  },
  {
    name: Modules.Chargebacks,
    path: '/chargebacks',
    tags: ['chargebacks'],
    childRoutes: [
      {
        name: `${Modules.Chargebacks} summary`,
        path: '/chargebacks?section=chargeback-summary',
        tags: ['chargeback summary', 'summary']
      },
      {
        name: `${Modules.Chargebacks} overview`,
        path: '/chargebacks?section=chargeback-overview',
        tags: ['chargeback overview', 'overview']
      },
      {
        name: `${Modules.Chargebacks} details`,
        path: '/chargebacks/details',
        tags: ['chargeback details']
      }
    ]
  },
  {
    name: 'Marketing',
    path: '/marketing/new',
    tags: ['marketing']
  },
  {
    name: 'Loop Integratoin Status',
    path: '/integration-status',
    tags: ['integration status']
  },
  {
    name: 'Loop Store Mapping',
    path: '/onboard/data',
    tags: ['loop store mapping']
  },
  {
    name: 'Finances',
    path: '/balance/transactions-and-payouts/transactions',
    tags: ['finances'],
    childRoutes: [
      {
        name: 'Transactions',
        path: '/balance/transactions-and-payouts/transactions',
        tags: ['transactions']
      },
      {
        name: 'Payouts',
        path: '/order/based/payouts',
        tags: ['payouts']
      },
      {
        name: 'By Store',
        path: '/order/based/by_store',
        tags: ['by store']
      },
      {
        name: 'Bank Reconciliation',
        path: '/reconciliation/bank_reconciliation',
        tags: ['bank reconciliation']
      }
    ]
  }
]

const flatRouteMap: RouteMap[] = []
for (let i = 0; i < routeMap.length; i++) {
  const route = routeMap[i]
  flatRouteMap.push(route)
  if (Array.isArray(route.childRoutes) && route.childRoutes.length > 0) {
    for (let j = 0; j < route.childRoutes.length; j++) {
      const childRoute = route.childRoutes[j]
      flatRouteMap.push({ ...childRoute, isChild: true })
    }
  }
}

export { flatRouteMap }

export default routeMap
