import { PLATFORM } from '@LoopKitchen/heyapi-client'
import { Modules, platformNames } from 'src/utils/config/config'
import { Dictionary } from 'src/utils/config/dictionary'

const tooltips = {
  sales_incl_tax: 'Total revenue generated from orders, including taxes charged to customers.',
  sales_excl_tax: 'Total revenue generated from orders, excluding any taxes charged to customers.',
  lost_sales: `Potential revenue lost due to ${Dictionary.CANCELLED.toLowerCase()} or failed orders.`,
  unfulfilled_refunds: 'Refunds due to order unfulfillment.',
  taxes: 'Taxes collected from customers and paid to you by 3P platforms.',
  taxes_withheld: 'Taxes collected from customers and held by 3P platforms to pay to tax authorities on your behalf.',
  tax_backup_withheld: `Amount withheld by third-party provider if taxpayer information is incomplete or not updated. For more info: https://shorturl.at/aVVXS (Specific to ${platformNames[PLATFORM.UBER_EATS]})`,
  commissions: 'Fees charged by the platform for facilitating orders.',
  restaurant_delivery_charge: `Charges collected from customers for restaurant delivery. (Specific to ${platformNames[PLATFORM.GRUBHUB]}, ${platformNames[PLATFORM.EZ_CATER]} and ${platformNames[PLATFORM.OLO]})`,
  marketing: 'Costs associated with promoting the restaurant on the platform.',
  marketing_ad_spend: 'Costs associated with advertising on the platform.',
  marketing_promo_spend: 'Costs associated with promotional activities on the platform.',
  loyalty: `The cost of the loyalty program. (Specific to ${platformNames[PLATFORM.OLO]})`,
  chargebacks: 'Refunds due to customer dissatisfaction.',
  won_disputes: `Includes won unfulfilled ${Modules.Chargebacks} disputes.`,
  customer_tips: 'Additional amounts paid by customers as tips for service.',
  restaurant_fees: 'Fees charged by the restaurant for facilitating orders',
  miscellaneous: 'Various fees or charges that do not fit into specific categories.',
  unaccounted: 'Amounts that are not allocated or categorized, often requiring further investigation or clarification.',
  cost_of_goods_sold: 'Costs associated with producing the goods or services sold.',
  marketing_fee: `A supplementary fee charged by ${platformNames[PLATFORM.DOORDASH]} for each successful promotional order. This is calculated on a best-efforts basis to approximate the cost associated with the promotion. (Specific to ${platformNames[PLATFORM.DOORDASH]})`,
  merchant_funded_discount: ` The portion of a promotional discount funded by the merchant as part of a marketing campaign. This is estimated on a best-efforts basis to reflect the merchant's contribution. (Specific to ${platformNames[PLATFORM.UBER_EATS]} and ${platformNames[PLATFORM.DOORDASH]})`,
  third_party_funded_discount: `The portion of a promotional discount covered by a third-party contributor, calculated on a best-efforts basis to represent the funding provided for the marketing promotion. (Specific to ${platformNames[PLATFORM.UBER_EATS]} and ${platformNames[PLATFORM.DOORDASH]})`
}

export default tooltips
