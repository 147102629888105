import { lazy } from 'react'
import { LoopModule } from 'src/services/openApiV2'

const GuardTrends = lazy(() => import('src/pages/members/StoreAvailability/Navs/Trends'))
const GuardRestoreHistory = lazy(() => import('src/pages/members/StoreAvailability/Navs/RestoreHistory'))
const GuardRealtimeStatus = lazy(() => import('src/pages/members/StoreAvailability/Navs/RealtimeStatus'))
const GuardNotifications = lazy(() => import('src/pages/members/StoreAvailability/Navs/Notifications'))
const GuardNotificationsSlack = lazy(() => import('src/pages/members/StoreAvailability/Navs/NotificationsSlack'))
const GuardHistoryByLostSales = lazy(() => import('src/pages/members/StoreAvailability/Navs/HistoryByLostSales'))
const GuardHistoryByAvgDowntime = lazy(() => import('src/pages/members/StoreAvailability/Navs/HistoryByAvgDowntime'))
const GuardHistoryByRestoreImpact = lazy(() => import('src/pages/members/StoreAvailability/Navs/HistoryByRestoreImpact'))
const GuardMatrix = lazy(() => import('src/pages/members/StoreAvailability/Navs/Matrix'))
const GuardDriverWaitTime = lazy(() => import('src/pages/members/DriverWaitTime/DriverWaitTime'))
const GuardRestoreConfig = lazy(() => import('src/pages/members/GuardRestoreConfig/GuardRestoreConfig'))
const Configurations = lazy(() => import('src/pages/members/Configurations/ConfigurationCenter'))
const Benchmarks = lazy(() => import('src/pages/members/Benchmarks/Benchmarks'))
const GuardProblematicItems = lazy(() => import('src/pages/members/GuardProblematicItems/GuardProblematicItems'))

export const existingRoutes = [
  {
    path: 'guard/trends',
    element: <GuardTrends />
  },
  {
    path: 'guard/restore/history',
    element: <GuardRestoreHistory />
  },
  {
    path: 'guard/restore/matrix',
    element: <GuardMatrix />
  },
  {
    path: 'guard/realtime/status',
    element: <GuardRealtimeStatus />
  },
  {
    path: 'guard/configurations/locations',
    element: <Configurations />
  },
  {
    path: 'guard/configurations/reasons',
    element: <GuardRestoreConfig />
  },
  {
    path: 'guard/notifications/slack',
    element: <GuardNotificationsSlack />
  },
  {
    path: 'guard/notifications/email_sms',
    element: <GuardNotifications />
  },
  {
    path: 'guard/history/lost_sales',
    element: <GuardHistoryByLostSales />
  },
  {
    path: 'guard/history/average_downtime',
    element: <GuardHistoryByAvgDowntime />
  },
  {
    path: 'guard/history/restore_impact',
    element: <GuardHistoryByRestoreImpact />
  },
  {
    path: 'guard/driver-wait-time',
    element: <GuardDriverWaitTime />
  },
  {
    path: 'guard/benchmarks',
    element: <Benchmarks module={LoopModule.GUARD} />
  }, 
  {
    path: 'guard/problematic-items',
    element: <GuardProblematicItems />
  }
]

export const moduleNamedRoutes = [
  {
    path: 'guard',
    element: <GuardTrends />,
    children: [
      { index: true, element: <GuardTrends /> },
      { path: 'trends', element: <GuardTrends /> },
      {
        path: 'restore',
        children: [
          { index: true, element: <GuardRestoreHistory /> },
          { path: 'history', element: <GuardRestoreHistory /> },
          { path: 'matrix', element: <GuardMatrix /> }
        ]
      },

      { path: 'realtime-status', element: <GuardRealtimeStatus /> },
      { path: 'driver-wait-time', element: <GuardDriverWaitTime /> },
      {
        path: 'configurations',
        children: [
          { index: true, element: <Configurations /> },
          { path: 'locations', element: <Configurations /> },
          { path: 'reasons', element: <GuardRestoreConfig /> }
        ]
      },
      {
        path: 'notifications',
        children: [
          { index: true, element: <GuardNotifications /> },
          { path: 'slack', element: <GuardNotificationsSlack /> },
          { path: 'email-sms', element: <GuardNotifications /> }
        ]
      },
      {
        path: 'history',
        children: [
          { index: true, element: <GuardHistoryByLostSales /> },
          { path: 'lost-sales', element: <GuardHistoryByLostSales /> },
          { path: 'average-downtime', element: <GuardHistoryByAvgDowntime /> },
          { path: 'restore-impact', element: <GuardHistoryByRestoreImpact /> }
        ]
      },
      { path: 'benchmarks', element: <Benchmarks module={LoopModule.GUARD} /> },
      { path: 'problematic-items', element: <GuardProblematicItems /> }
    ]
  }
]

export const guardRoutes = [...existingRoutes, ...moduleNamedRoutes]
