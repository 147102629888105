import { IosSwitch, MultiSelect } from '@LoopKitchen/loop-ui'
import { DateRange, Insights, Storefront, SvgIconComponent } from '@mui/icons-material'
import { Box, Chip, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import CustomMaterialTableWrapper from 'src/components/CustomMaterialTableWrapper/CustomMaterialTableWrapper'
import WarningDiamondIcon from 'src/components/Icon/WarningDiamondIcon'
import PlatformLogo from 'src/components/PlatformLogo'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { DateRangeType } from 'src/context/NewFilterContext/utils/FilterGraph'
import { useSnackData } from 'src/context/SnackContext'
import {
  CampaignAudience,
  CampaignDetailPaginatedV3,
  CampaignPeriodType,
  DefaultService,
  EvaluationCampaignsV2,
  EvaluationCampaignsV2FiltersPaginated
} from 'src/services/openApiV2'
import { filterNames } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import { downloadCsv, formatCurrency, formatNumber } from 'src/utils/functions'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'

export const CampaignFlagProperties = {
  low_performing: {
    primary: '#E52E2E',
    secondary: '#FFE5E5',
    title: 'Low Performing',
    icon: <WarningDiamondIcon sx={{ color: '#E52E2E', fontSize: '14px' }} />
  },
  recently_added: {
    primary: '#004DE5',
    secondary: '#E0EBFF',
    title: 'New',
    icon: <DateRange sx={{ color: '#004DE5', fontSize: '14px' }} />
  }
}

interface CampaignTableProps {
  ref_period?: DateRangeType
  eval_period?: DateRangeType
  campaign_period_type?: CampaignPeriodType
  is_eval_table?: boolean
  showRecentlyAdded?: boolean
  setShowRecentlyAdded?: (val: boolean) => void
  setShowAllCampaigns?: (val: boolean) => void
  onCampaignRowClick?: (campaignDetails: CampaignTableDataType) => void
}

enum METRICS {
  SALES = 'Sales',
  SPEND = 'Spend',
  GROSS_MARGIN = 'Gross Margin',
  DOUBLE_DIP_RATE = 'Double Dip Rate',
  EFF_ROI = 'Effective ROI',
  COFUND = 'Cofunding',
  BUDGET = 'Budget'
}

const metricFieldMap: Record<METRICS, string | null> = {
  Sales: 'sales',
  Spend: 'cost',
  'Double Dip Rate': 'attr_multi_campaign_orders_perc',
  'Gross Margin': 'attr_payout_perc',
  'Effective ROI': 'attr_eff_roi',
  Cofunding: 'attr_cofund',
  Budget: 'budget'
}

interface IconTooltipProps {
  tooltip: string
  position: 'left' | 'right' | 'top' | 'bottom'
  icon: SvgIconComponent
}

const IconTooltip = ({ tooltip, position, icon: Icon }) => {
  return (
    <Tooltip
      title={tooltip}
      arrow
      placement={position}
      componentsProps={{
        tooltip: {
          style: {
            color: 'white',
            fontSize: 12,
            fontWeight: 400,
            maxWidth: 300,
            padding: '10px',
            backgroundColor: 'rgba(0, 0, 0, 1)'
          }
        },
        arrow: {
          style: {
            color: 'black'
          }
        }
      }}>
      <Box
        sx={{
          fontSize: '18px',
          color: 'black',
          borderRadius: '2px',
          bgcolor: '#E8E8E8',
          width: '25px',
          height: '25px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Icon
          fontSize="inherit"
          color="inherit"
        />
      </Box>
    </Tooltip>
  )
}

export type CampaignTableDataType = EvaluationCampaignsV2 | CampaignDetailPaginatedV3

type CampaignTablePaginatedType = {
  data: CampaignTableDataType[]
  next_offset?: number
  max_rows?: number
  table_id?: string
}

export default function CampaignTable({
  ref_period = null,
  eval_period = null,
  campaign_period_type = null,
  is_eval_table = false,
  onCampaignRowClick = null
}: CampaignTableProps) {
  const { headerHeightPx } = useLayoutDimension()
  const [data, setData] = React.useState<CampaignTablePaginatedType>(null)
  const [loading, setLoading] = React.useState(false)
  const [orderBy, setOrderBy] = React.useState<string>('cost')
  const [ascending, setAscending] = React.useState<boolean>(false)
  const [showLowPerformingCampaign, setShowLowPerformingCampaign] = React.useState(false)

  const selectedMetrics = [METRICS.SALES, METRICS.SPEND, METRICS.COFUND, METRICS.GROSS_MARGIN, METRICS.DOUBLE_DIP_RATE, METRICS.EFF_ROI]
  // Exclude budget on eval playground for nowC
  if (campaign_period_type === null) {
    selectedMetrics.push(METRICS.BUDGET)
  }
  const [searchText, setSearchText] = React.useState<string>('')

  const campaignTypes = ['Bogo', 'Discounted_item', 'Flat', 'Free_delivery', 'Free_item', 'Ad', 'Other']
  const [selectedCampaignType, setSelectedCampaignType] = React.useState<string[]>(campaignTypes)
  const { getFiltersV2, getFilters, lastDate } = useFilter()
  const { setDownloadSnack, openSuccess } = useSnackData()
  const { handleError } = useErrorData()

  const tableData = React.useMemo(() => {
    return get(data, 'data', [] as typeof data.data)
  }, [data])

  const getCSVString = async (): Promise<string> => {
    const filterObj = {
      campaign_flag: showLowPerformingCampaign ? 'low_performing' : undefined,
      campaignType_in: selectedCampaignType.join('|') || undefined,
      search_term: searchText,
      ...getFiltersV2(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)
    }

    if (campaign_period_type === null) {
      if (ref_period) {
        filterObj['start_date_in'] = ref_period.start.format('YYYY-MM-DD')
        filterObj['end_date_in'] = ref_period.end.format('YYYY-MM-DD')
      }
    } else {
      filterObj['ref_start_date_in'] = ref_period.start.format('YYYY-MM-DD')
      filterObj['ref_end_date_in'] = ref_period.end.format('YYYY-MM-DD')

      filterObj['eval_start_date_in'] = eval_period.start.format('YYYY-MM-DD')
      filterObj['eval_end_date_in'] = eval_period.end.format('YYYY-MM-DD')

      filterObj['campaign_period'] = campaign_period_type
      filterObj['order_by'] = `ref_${orderBy} + eval_${orderBy}`
    }

    if (campaign_period_type) {
      return (await DefaultService.exportEvaluationCampaignsV2ApiExportEvaluationCampaignsV2Post({
        requestBody: { ...(filterObj as EvaluationCampaignsV2FiltersPaginated) }
      })) as string
    } else {
      return (await DefaultService.exportCampaignDetailV3ApiExportCampaignDetailV3Post({ requestBody: filterObj })) as string
    }
  }

  const exportCSV = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      const res = await getCSVString()
      downloadCsv('campaign.csv', res)
      setDownloadSnack({ status: 'complete' })
    } catch (err) {
      setDownloadSnack({ status: 'close' })
      handleError(err.message)
    }
  }

  const getData = async (
    obj: { offset: number; order_by?: string; ascending?: boolean; limit?: number },
    callback: (data: CampaignTablePaginatedType) => void
  ) => {
    setLoading(true)
    try {
      const filterObj = {
        limit: 100,
        campaign_flag: showLowPerformingCampaign ? 'low_performing' : undefined,
        campaignType_in: selectedCampaignType.join('|') || undefined,
        search_term: searchText,
        ...obj,
        ...getFiltersV2(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)
      }

      if (campaign_period_type === null) {
        if (ref_period) {
          filterObj['start_date_in'] = ref_period.start.format('YYYY-MM-DD')
          filterObj['end_date_in'] = ref_period.end.format('YYYY-MM-DD')
        }
      } else {
        filterObj['ref_start_date_in'] = ref_period.start.format('YYYY-MM-DD')
        filterObj['ref_end_date_in'] = ref_period.end.format('YYYY-MM-DD')

        filterObj['eval_start_date_in'] = eval_period.start.format('YYYY-MM-DD')
        filterObj['eval_end_date_in'] = eval_period.end.format('YYYY-MM-DD')

        filterObj['campaign_period'] = campaign_period_type
        filterObj['order_by'] =
          campaign_period_type === CampaignPeriodType.COMMON
            ? `ref_${orderBy} + eval_${orderBy}`
            : campaign_period_type === CampaignPeriodType.REFERENCE
              ? `ref_${orderBy}`
              : `eval_${orderBy}`
      }

      const res = campaign_period_type
        ? await DefaultService.callEvaluationCampaignsV2ApiPaginatedEvaluationCampaignsV2Post({
            requestBody: { ...(filterObj as EvaluationCampaignsV2FiltersPaginated) }
          })
        : await DefaultService.callCampaignDetailV3ApiPaginatedCampaignDetailV3Post({ requestBody: filterObj })
      callback(res)
    } catch (err) {
      handleError(err.message)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (is_eval_table && !campaign_period_type) {
      return
    }
    getData({ offset: 0, order_by: orderBy, ascending }, (res) => {
      setData(res)
    })
  }, [
    orderBy,
    ascending,
    showLowPerformingCampaign,
    selectedCampaignType,
    searchText,
    ref_period,
    eval_period,
    campaign_period_type,
    ...getFilters(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'])
  ])

  return (
    <>
      <Box
        display="flex"
        flexDirection="column">
        {/* <Box
          display="flex"
          alignItems="center"
          gap={3}>


          {!period && (
              <FormControlLabel
                value="recently_added"
                control={
                  <IosSwitch
                    icon={
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          borderRadius: '50%',
                          backgroundColor: '#fff'
                        }}
                      />
                    }
                    checkedIcon={
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          borderRadius: '50%',
                          backgroundColor: '#fff'
                        }}
                      />
                    }
                    sx={{ height: '18px', width: '34px' }}
                  />
                }
                label={
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="#696C80"
                    sx={{ ml: 1 }}>
                    Recently Added (Last 30 days)
                  </Typography>
                }
                checked={showRecentlyAdded}
                onChange={(e, checked) => {
                  setShowRecentlyAdded(checked)
                }}
                sx={{ m: 0 }}
              />
          )}
        </Box> */}

        <CustomMaterialTableWrapper
          data={tableData}
          isLoading={loading}
          onRowClick={onCampaignRowClick ? (data) => onCampaignRowClick(data) : undefined}
          onExportModifyCSV={getCSVString}
          onExportCSV={() => {
            exportCSV()
          }}
          onSearchChange={(searchText) => {
            setSearchText(searchText)
          }}
          onOrderByChange={(type) => {
            setAscending(type === 'ascending')
          }}
          onSortByChange={(option) => {
            setOrderBy(option.value)
          }}
          onLastPage={() => {
            const nextOffset = get(data, 'next_offset', undefined)
            if (nextOffset) {
              getData({ offset: nextOffset, order_by: orderBy, ascending }, (res) => {
                setData((prev) => ({
                  ...res,
                  data: [...get(prev, 'data', []), ...get(res, 'data', [])]
                }))
              })
            }
          }}
          titleComp={
            <Stack
              direction="row"
              alignItems="center"
              gap="16px">
              <MultiSelect
                value={selectedCampaignType}
                disableSort
                disableSearch
                onChange={(val) => {
                  setSelectedCampaignType(val)
                }}
                options={[
                  ...campaignTypes.map((e) => {
                    return {
                      label: e.replaceAll('_', ' '),
                      value: e
                    }
                  })
                ]}
                selectButtonSx={{
                  height: '40px',
                  width: '140px',
                  bgcolor: '#F6f6f6',
                  color: '#000',
                  '&:hover': {
                    bgcolor: '#EEEEEE'
                  },
                  '& p': {
                    color: '#000'
                  },
                  borderRadius: '4px',
                  px: '16px',
                  py: '5px'
                }}
              />
              <FormControlLabel
                value="low_performing"
                control={
                  <IosSwitch
                    icon={
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          borderRadius: '50%',
                          backgroundColor: '#fff'
                        }}
                      />
                    }
                    checkedIcon={
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          borderRadius: '50%',
                          backgroundColor: '#fff'
                        }}
                      />
                    }
                    sx={{ height: '18px', width: '34px' }}
                  />
                }
                label={
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="#696C80"
                    sx={{ ml: 1 }}>
                    Low Performing Campaigns
                  </Typography>
                }
                checked={showLowPerformingCampaign}
                onChange={(e, checked) => {
                  setShowLowPerformingCampaign(checked)
                }}
                sx={{ m: 0 }}
              />
              <Box sx={{ flexGrow: 1 }} /> {/* This pushes the tooltip to the right */}
              <Typography
                fontSize="12px"
                fontWeight={600}
                color="#696C80">
                Metric Source
              </Typography>
              <IconTooltip
                tooltip={
                  'Measured using orders our system could smartly match from order-level financial reports against merchant-reported daily marketing data.'
                }
                icon={Insights}
                position={'bottom'}
              />
              <IconTooltip
                tooltip={'Measured using merchant-reported daily marketing data'}
                icon={Storefront}
                position={'bottom'}
              />
            </Stack>
          }
          columns={[
            {
              title: 'Campaign',
              field: 'campaignName',
              cellStyle: { minWidth: '250px' },
              render: (data: CampaignTableDataType) => {
                return (
                  <Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      gap={2}
                      p={1}
                      bgcolor="#F4F4F4"
                      borderRadius="4px"
                      minWidth={270}
                      // sx={{
                      //   cursor: 'pointer',
                      //   transition: '0.3s ease',
                      //   '&:hover': {
                      //     bgcolor: '#E6E6E6'
                      //   }
                      // }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        gap={1}>
                        <Box
                          p={'2px'}
                          bgcolor="#fff">
                          <PlatformLogo
                            shortImg
                            platformName={get(data, 'vb_platform', '')}
                            width="24px"
                            height="26px"
                            boxSx={{
                              display: 'flex',
                              alignItems: 'center',
                              flex: 'none'
                            }}
                          />
                        </Box>

                        <Typography
                          fontSize="13px"
                          fontWeight={600}
                          color="#000"
                          lineHeight="initial">
                          {get(data, 'campaignName', '')}
                        </Typography>
                      </Box>

                      {/* <ChevronRight sx={{ fontSize: '20px', color: '#000' }} /> */}
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      flexWrap="wrap"
                      gap={1}
                      mt={1}>
                      {data.audience !== CampaignAudience.UNKNOWN && (
                        <Tooltip title={`Audience`}>
                          <Chip
                            sx={{ borderRadius: '12px', fontSize: '12px', fontWeight: 500, pt: 0 }}
                            label={data.audience}
                          />
                        </Tooltip>
                      )}
                      {get(data, 'campaign_flag', '') === 'low_performing' && (
                        <Box
                          display="flex"
                          alignItems="center"
                          padding="10px 10px"
                          borderRadius="12px"
                          width="fit-content"
                          bgcolor={CampaignFlagProperties['low_performing'].secondary}
                          gap={0.5}>
                          {CampaignFlagProperties['low_performing'].icon}
                          <Typography
                            color={CampaignFlagProperties['low_performing'].primary}
                            fontWeight={500}
                            fontSize="11px"
                            lineHeight="initial"
                            whiteSpace="nowrap">
                            {CampaignFlagProperties['low_performing'].title}
                          </Typography>
                        </Box>
                      )}

                      {moment(get(data, 'startTime', '')).isSameOrAfter(moment(lastDate).subtract(1, 'month')) && (
                        <Box
                          display="flex"
                          alignItems="center"
                          padding="4px 10px"
                          borderRadius="12px"
                          width="fit-content"
                          bgcolor={CampaignFlagProperties['recently_added'].secondary}
                          gap={0.5}>
                          {CampaignFlagProperties['recently_added'].icon}
                          <Typography
                            color={CampaignFlagProperties['recently_added'].primary}
                            fontWeight={500}
                            fontSize="11px"
                            lineHeight="initial"
                            whiteSpace="nowrap">
                            {CampaignFlagProperties['recently_added'].title}
                          </Typography>
                        </Box>
                      )}

                      {get(data, 'startTime', null) && (
                        <Typography
                          fontSize="12px"
                          color="#000">
                          From {moment(get(data, 'startTime', '')).format('DD MMM YYYY')}
                          {` till ${!data.endTime || data.endTime === null || data.endTime === '' || Math.abs(moment().diff(moment(get(data, 'endTime', '')), 'years')) >= 90 ? 'Indefinite' : moment(get(data, 'endTime', '')).format('DD MMM YYYY')}`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                )
              }
            },
            {
              title: filterNames.vb_name,
              field: 'vb_name',
              cellStyle: { minWidth: '200px' },
              render: (data) => {
                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={0.5}>
                    <Typography
                      fontSize="12px"
                      fontWeight={600}
                      color="#000">
                      {get(data, 'vb_name', '')}
                    </Typography>
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color="#000">
                      {get(data, 'b_name', '')}
                    </Typography>
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color="#0008">
                      {get(data, 'vb_address', '')}
                    </Typography>
                  </Box>
                )
              }
            },
            // {
            //   title: (
            //     <Tooltip title="Total marketing orders reported by merchant platform">
            //       <Typography
            //         fontSize="12px"
            //         fontWeight={600}
            //         color="black"
            //         display="flex"
            //         alignItems="center">
            //         <Storefront sx={{ fontSize: 16, mr: '8px' }} />
            //         Orders
            //       </Typography>
            //     </Tooltip>
            //   ) as any,
            //   field: 'orders',
            //   hideInExport: true,
            //   alignType: 'currency',
            //   cellStyle: {
            //     textAlign: 'center'
            //   },
            //   render: (data) => {
            //     return (
            //       <RenderNumber
            //         data={data}
            //         field={'orders'}
            //         campaign_period_type={campaign_period_type}
            //       />
            //     )
            //   }
            // },
            {
              title: (
                <Tooltip title="Total marketing sales reported by merchant platform">
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="black"
                    display="flex"
                    alignItems="center">
                    <Storefront sx={{ fontSize: 16, mr: '8px' }} />
                    Sales
                  </Typography>
                </Tooltip>
              ) as any,
              field: metricFieldMap[METRICS.SALES],
              hideInExport: true,
              alignType: 'currency',
              cellStyle: {
                textAlign: 'center'
              },
              render: (data) => {
                return (
                  <RenderCurrency
                    data={data}
                    field={metricFieldMap[METRICS.SALES]}
                    campaign_period_type={campaign_period_type}
                    color="#0E8C43"
                  />
                )
              }
            },
            {
              title: (
                <Tooltip title="Total marketing spend reported by merchant platform.">
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="black"
                    display="flex"
                    alignItems="center">
                    <Storefront sx={{ fontSize: 16, mr: '8px' }} />
                    Spend
                  </Typography>
                </Tooltip>
              ) as any,
              field: metricFieldMap[METRICS.SPEND],
              hidden: !selectedMetrics.includes(METRICS.SPEND),
              hideInExport: true,
              alignType: 'currency',
              cellStyle: {
                textAlign: 'center'
              },
              render: (data) => {
                return (
                  <RenderCurrency
                    data={data}
                    field={metricFieldMap[METRICS.SPEND]}
                    campaign_period_type={campaign_period_type}
                  />
                )
              }
            },

            {
              title: (
                <Tooltip title="The ratio of (Marketing Driven Sales) to (Effective Marketing Spend)">
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="black"
                    display="flex"
                    alignItems="center">
                    <Insights sx={{ fontSize: 16, mr: '8px' }} />
                    Effective ROI
                  </Typography>
                </Tooltip>
              ) as any,
              field: metricFieldMap[METRICS.EFF_ROI],
              hidden: !selectedMetrics.includes(METRICS.EFF_ROI),
              hideInExport: true,
              sortable: true,
              cellStyle: {
                textAlign: 'center'
              },
              render: (data) => {
                return (
                  <RenderNumber
                    data={data}
                    field={metricFieldMap[METRICS.EFF_ROI]}
                    campaign_period_type={campaign_period_type}
                    maxFractionDigits={2}
                  />
                )
              }
            },
            {
              title: (
                <Tooltip title="Percentage of orders attributed to this campaign having marketing spends linked to other campaigns">
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="black"
                    display="flex"
                    alignItems="center">
                    <Insights sx={{ fontSize: 16, mr: '8px' }} />
                    Double Dip Rate
                  </Typography>
                </Tooltip>
              ) as any,
              field: metricFieldMap[METRICS.DOUBLE_DIP_RATE],
              hidden: !selectedMetrics.includes(METRICS.DOUBLE_DIP_RATE),
              hideInExport: true,
              cellStyle: {
                textAlign: 'center'
              },
              render: (data) => {
                return (
                  <RenderNumber
                    data={data}
                    field={metricFieldMap[METRICS.DOUBLE_DIP_RATE]}
                    campaign_period_type={campaign_period_type}
                    suffix="%"
                  />
                )
              }
            },
            {
              title: (
                <Tooltip title="Net Payout divided by Sales">
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="black"
                    display="flex"
                    alignItems="center">
                    <Insights sx={{ fontSize: 16, mr: '8px' }} />
                    Gross Margin
                  </Typography>
                </Tooltip>
              ) as any,
              field: metricFieldMap[METRICS.GROSS_MARGIN],
              hidden: !selectedMetrics.includes(METRICS.GROSS_MARGIN),
              hideInExport: true,
              cellStyle: {
                textAlign: 'center'
              },
              render: (data) => {
                return (
                  <RenderNumber
                    data={data}
                    field={metricFieldMap[METRICS.GROSS_MARGIN]}
                    campaign_period_type={campaign_period_type}
                    suffix="%"
                  />
                )
              }
            },

            {
              title: (
                <Tooltip title="Total amount of cofunding received by all marketing efforts for orders attributed to this campaign.">
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="black"
                    display="flex"
                    alignItems="center">
                    <Insights sx={{ fontSize: 16, mr: '8px' }} />
                    Cofunding
                  </Typography>
                </Tooltip>
              ) as any,
              field: metricFieldMap[METRICS.COFUND],
              hidden: !selectedMetrics.includes(METRICS.COFUND),
              hideInExport: true,
              cellStyle: {
                textAlign: 'center'
              },
              render: (data) => {
                return (
                  <RenderCurrency
                    data={data}
                    field={metricFieldMap[METRICS.COFUND]}
                    campaign_period_type={campaign_period_type}
                  />
                )
              }
            },

            {
              title: (
                <Tooltip title="Target marketing spend allocated per week for this campaign">
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="black"
                    display="flex"
                    alignItems="center">
                    <Storefront sx={{ fontSize: 16, mr: '8px' }} />
                    {METRICS.BUDGET}
                  </Typography>
                </Tooltip>
              ) as any,
              field: metricFieldMap[METRICS.BUDGET],
              hidden: !selectedMetrics.includes(METRICS.BUDGET),
              hideInExport: true,
              cellStyle: {
                textAlign: 'center'
              },
              render: (data) => {
                return (
                  <Typography
                    fontSize="12px"
                    color="#000">
                    {data.budget && data.budget > 0 ? formatCurrency(get(data, metricFieldMap[METRICS.BUDGET], 0), { maxFractionDigits: 2 }) : 'Uncapped'}
                  </Typography>
                )
              }
            }
          ]}
          options={{
            stickyHeader: ref_period ? '-17px' : headerHeightPx,
            export: true,
            extraExportColumns: [
              { title: filterNames.b_name, field: 'b_name', hideInExport: false },
              { title: filterNames.vb_address, field: 'vb_address', hideInExport: false },
              { title: filterNames.vb_platform, field: 'vb_platform', hideInExport: false },
              { title: 'Merchant Reported Sales (Reference)', field: 'ref_sales', hideInExport: !campaign_period_type },
              { title: 'Merchant Reported Sales (Evaluation)', field: 'eval_sales', hideInExport: !campaign_period_type },
              { title: 'Attributed Sales (Reference)', field: 'ref_attr_sales', hideInExport: !campaign_period_type },
              { title: 'Attributed Sales (Evaluation)', field: 'eval_attr_sales', hideInExport: !campaign_period_type },
              { title: 'Merchant Reported Spend (Reference)', field: 'ref_cost', hideInExport: !campaign_period_type },
              { title: 'Merchant Reported Spend (Evaluation)', field: 'eval_cost', hideInExport: !campaign_period_type },
              { title: 'Attributed Spend (Reference)', field: 'ref_attr_spend', hideInExport: !campaign_period_type },
              { title: 'Attributed Spend (Evaluation)', field: 'eval_attr_spend', hideInExport: !campaign_period_type },
              { title: 'Merchant Reported Orders (Reference)', field: 'ref_orders', hideInExport: !campaign_period_type },
              { title: 'Merchant Reported Orders (Evaluation)', field: 'eval_orders', hideInExport: !campaign_period_type },
              { title: 'Attributed Orders (Reference)', field: 'ref_attr_orders', hideInExport: !campaign_period_type },
              { title: 'Attributed Orders (Evaluation)', field: 'eval_attr_orders', hideInExport: !campaign_period_type },
              { title: '3P ROI (Reference)', field: 'ref_roi', hideInExport: !campaign_period_type },
              { title: '3P ROI (Evaluation)', field: 'eval_roi', hideInExport: !campaign_period_type },
              { title: 'Effective ROI (Reference)', field: 'ref_attr_eff_roi', hideInExport: !campaign_period_type },
              { title: 'Effective ROI (Evaluation)', field: 'eval_attr_eff_roi', hideInExport: !campaign_period_type },
              { title: 'Attributed Cofund (Reference)', field: 'ref_attr_cofund', hideInExport: !campaign_period_type },
              { title: 'Attributed Cofund (Evaluation)', field: 'eval_attr_cofund', hideInExport: !campaign_period_type },
              { title: 'Attributed Payout (Reference)', field: 'ref_attr_payout', hideInExport: !campaign_period_type },
              { title: 'Attributed Payout (Evaluation)', field: 'eval_attr_payout', hideInExport: !campaign_period_type },
              { title: 'Double Dip Rate (Reference)', field: 'ref_attr_multi_campaign_orders_perc', hideInExport: !campaign_period_type },
              { title: 'Double Dip Rate (Evaluation)', field: 'eval_attr_multi_campaign_orders_perc', hideInExport: !campaign_period_type },
              { title: 'Cohort', field: 'audience', hideInExport: false },
              { title: 'Unattributed Sales (Reference)', field: 'ref_unattr_sales', hideInExport: !campaign_period_type },
              { title: 'Unattributed Sales (Evaluation)', field: 'eval_unattr_sales', hideInExport: !campaign_period_type },
              { title: 'Unattributed Spend (Reference)', field: 'ref_unattr_spend', hideInExport: !campaign_period_type },
              { title: 'Unattributed Spend (Evaluation)', field: 'eval_unattr_spend', hideInExport: !campaign_period_type },
              { title: 'Unattributed Orders (Reference)', field: 'ref_unattr_orders', hideInExport: !campaign_period_type },
              { title: 'Unattributed Orders (Evaluation)', field: 'eval_unattr_orders', hideInExport: !campaign_period_type },
              { title: 'Merchant Reported Sales', field: 'sales', hideInExport: !!campaign_period_type },
              { title: 'Attributed Sales', field: 'attr_sales', hideInExport: !!campaign_period_type },
              { title: 'Merchant Reported Spend', field: 'cost', hideInExport: !!campaign_period_type },
              { title: 'Attributed Spend', field: 'attr_spend', hideInExport: !!campaign_period_type },
              { title: 'Merchant Reported Orders', field: 'orders', hideInExport: !!campaign_period_type },
              { title: 'Attributed Orders', field: 'attr_orders', hideInExport: !!campaign_period_type },
              { title: '3P ROI', field: 'roi', hideInExport: !!campaign_period_type },
              { title: 'Effective ROI', field: 'attr_eff_roi', hideInExport: !!campaign_period_type },
              { title: 'Attributed Cofund', field: 'attr_cofund', hideInExport: !!campaign_period_type },
              { title: 'Double Dip Rate', field: 'attr_multi_campaign_orders_perc', hideInExport: !!campaign_period_type },
              { title: 'Unattributed Sales', field: 'unattr_sales', hideInExport: !!campaign_period_type },
              { title: 'Unattributed Spend', field: 'unattr_spend', hideInExport: !!campaign_period_type },
              { title: 'Unattributed Orders', field: 'unattr_orders', hideInExport: !!campaign_period_type },
              { title: 'Gross Margin', field: metricFieldMap[METRICS.GROSS_MARGIN] }
            ],
            search: true,
            sortable: true,
            showOrderBy: true,
            initialOrderBy: ascending ? 'ascending' : 'descending',
            pagination: true,
            totalPaginatedDataLength: get(data, 'max_rows', undefined),
            debounceMilliseconds: 1000
          }}
        />
      </Box>
    </>
  )
}

const RenderCurrency = ({ data, field, campaign_period_type, color = '#000' }) => {
  return campaign_period_type === CampaignPeriodType.COMMON ? (
    <Typography
      width={'150px'}
      fontSize="12px"
      color={color}>
      {formatCurrency(get(data, `ref_${field}`, 0), { maxFractionDigits: 0 }) + ' | ' + formatCurrency(get(data, `eval_${field}`, 0), { maxFractionDigits: 0 })}
    </Typography>
  ) : campaign_period_type === CampaignPeriodType.REFERENCE ? (
    <Typography
      fontSize="12px"
      color={color}>
      {formatCurrency(get(data, `ref_${field}`, 0), { maxFractionDigits: 2 })}
    </Typography>
  ) : campaign_period_type === CampaignPeriodType.EVALUATION ? (
    <Typography
      fontSize="12px"
      color={color}>
      {formatCurrency(get(data, `eval_${field}`, 0), { maxFractionDigits: 2 })}
    </Typography>
  ) : (
    <Typography
      fontSize="12px"
      color={color}>
      {formatCurrency(get(data, field, 0), { maxFractionDigits: 2 })}
    </Typography>
  )
}

const RenderNumber = ({ data, field, campaign_period_type, color = '#000', suffix = '', maxFractionDigits = 0 }) => {
  return campaign_period_type === CampaignPeriodType.COMMON ? (
    <Typography
      width={'150px'}
      fontSize="12px"
      color={color}>
      {formatNumber(get(data, `ref_${field}`, 0), { maxFractionDigits: maxFractionDigits }) +
        suffix +
        ' | ' +
        formatNumber(get(data, `eval_${field}`, 0), { maxFractionDigits: maxFractionDigits }) +
        suffix}
    </Typography>
  ) : campaign_period_type === CampaignPeriodType.REFERENCE ? (
    <Typography
      fontSize="12px"
      color={color}>
      {formatNumber(get(data, `ref_${field}`, 0), { maxFractionDigits: 2 }) + suffix}
    </Typography>
  ) : campaign_period_type === CampaignPeriodType.EVALUATION ? (
    <Typography
      fontSize="12px"
      color={color}>
      {formatNumber(get(data, `eval_${field}`, 0), { maxFractionDigits: 2 }) + suffix}
    </Typography>
  ) : (
    <Typography
      fontSize="12px"
      color={color}>
      {formatNumber(get(data, field, 0), { maxFractionDigits: 2 }) + suffix}
    </Typography>
  )
}
