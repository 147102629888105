import { RouteObject } from 'react-router-dom'
import TermsOfService from 'src/pages/public/terms/TermsOfService'
// User Profile

const existingRoutes: RouteObject[] = [
  {
    path: 'terms-of-service',
    element: TermsOfService()
  }
]

export const moduleNamedRoutes = []

export const generalRoutes = [...existingRoutes, ...moduleNamedRoutes]
