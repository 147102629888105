import Transactions from './Tabs/Transactions'
import BreakdownDataContextProvider from './utils/context/PayoutsBreakdownDataContext'

export default function Finance(): JSX.Element {
  return (
    <BreakdownDataContextProvider>
      <Transactions />
    </BreakdownDataContextProvider>
  )
}
