import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const CardReconciliation = lazy(() => import('src/pages/members/Pos/CardReconciliation'))
const ThirdPartyReconciliation = lazy(() => import('src/pages/members/Pos/ThirdPartyReconciliation'))
const ThirdPartyOrdersReconciliation = lazy(() => import('src/pages/members/Pos/ThirdPartyOrdersReconciliation'))
// Order Based Paying
const FinancePayouts = lazy(() => import('src/pages/members/Finance/Finance'))
const BankReconciliation = lazy(() => import('src/pages/members/Finance/SubPages/BankReconciliation'))
const ListOfPayouts = lazy(() => import('src/pages/members/Finance/SubPages/ListOfPayouts'))
const OrderBasedPaying = lazy(() => import('src/pages/members/OrderBasedPaying/OrderBasedPaying'))
const OrdersAndTransactions = lazy(() => import('src/pages/members/OrdersAndTransactions/OrdersAndTransactions'))

const AccountingPortal = lazy(() => import('src/pages/members/AccountingPortal/AccountingPortal'))

const OloTransactions = lazy(() => import('src/pages/members/OloTransactions/OloTransactions'))

const OloCustomers = lazy(() => import('src/pages/members/OloCustomers/OloCustomers'))

const OloItems = lazy(() => import('src/pages/members/OloItems/OloItems'))

const BankAccounts = lazy(() => import('src/pages/members/OrderBasedPaying/components/BankAccountMappings/BankAccountMappings'))

const Mapping = lazy(() => import('src/pages/members/Mapping/Mapping'))

const JESettings = lazy(() => import('src/pages/members/BookkeepingSetup/JESettings'))

const BookkeepingSetupConfigureChartOfAccounts = lazy(() => import('src/pages/members/BookkeepingSetup/ConfigureChartOfAccounts'))
const BookkeepingSetupSelectLocations = lazy(() => import('src/pages/members/BookkeepingSetup/SelectLocations'))
const BookkeepingSetupConfigureVariables = lazy(() => import('src/pages/members/BookkeepingSetup/ConfigureVariables'))
const BookkeepingSetupReviewSetup = lazy(() => import('src/pages/members/BookkeepingSetup/ReviewSetup'))
const TransactionsByStore = lazy(() => import('src/pages/members/TransactionsByStore/TransactionsByStore'))

const AccountingCadence = lazy(() => import('src/pages/members/AccountingSetup/AccountingCadence'))

const AddAccountingCadence = lazy(() => import('src/pages/members/AccountingSetup/AddAccountingCadence'))

const AccountingTemplate = lazy(() => import('src/pages/members/AccountingSetup/AccountingTemplate'))

const AccountingHistory = lazy(() => import('src/pages/members/AccountingSetup/AccountingHistory'))

const CrunchtimeConfiguration = lazy(() => import('src/pages/members/Crunchtime/CrunchtimeConfiguration'))
const PosReport = lazy(() => import('src/pages/members/Pos/PosReport/PosReport'))

const AccountingLocationStatus = lazy(() => import('src/pages/members/AccountingSetup/AccountingLocationStatus'))
const AccountingSetup = lazy(() => import('src/pages/members/AccountingSetup/AccountingSetup'))

const AddAccountingSoftware = lazy(() => import('src/pages/members/AccountingSetup/AddAccountingSoftware'))

const AccountingConfigurationV2 = lazy(() => import('src/pages/members/AccountingSetup/AccountingConfigurationV2'))

const RoyaltyOverview = lazy(() => import('src/pages/members/Royalty/RoyaltyOverview'))

const TaxOverview = lazy(() => import('src/pages/members/Tax/TaxOverview'))

const TaxByStore = lazy(() => import('src/pages/members/Tax/TaxByStore'))

export const existingRoutes = [
  // { path: 'order/based/payouts', element: <FinancePayouts /> },
  { path: 'order/based/payouts', element: <OrderBasedPaying /> },
  { path: 'order/based/reports', element: <OrderBasedPaying /> },
  { path: 'order/based/payouts/list', element: <ListOfPayouts /> },
  {
    path: 'order/based/by_store',
    element: <OrderBasedPaying tab="by_store" />
  },
  {
    path: 'reconciliation/bank_reconciliation',
    element: <OrderBasedPaying tab="bank_reconciliation" />
  },
  {
    path: 'order/based/by_store',
    element: <BankReconciliation />
  },
  // { path: 'order/based/transactions', element: <OrdersAndTransactions /> },
  { path: 'reconciliation/order/based/transactions', element: <OrdersAndTransactions /> },
  { path: 'reconciliation/order/based/transactions/non_variance_zero', element: <OrdersAndTransactions show_only_non_variance_zero /> },
  { path: 'order/based/overview', element: <FinancePayouts /> },
  { path: 'mapping', element: <Mapping /> },

  { path: 'accounting-portal/:tab', element: <AccountingPortal /> },
  {
    path: 'order/based/accounting-portal',
    element: (
      <Navigate
        to="/bookkeeping/gl-entries"
        replace
      />
    )
  },
  { path: 'codat/link', element: <AccountingPortal /> },
  { path: 'mapping', element: <Mapping /> },

  // { path: 'benchmarks', element: <Benchmarks /> },
  { path: 'olo/transactions', element: <OloTransactions /> },
  { path: 'olo/customers', element: <OloCustomers /> },
  { path: 'olo/items', element: <OloItems /> },
  { path: 'order/based/plaid/accounts', element: <BankAccounts /> },
  { path: 'bookkeeping/gl-entries', element: <AccountingPortal /> },
  { path: 'bookkeeping/add-bank', element: <BankAccounts /> },
  {
    path: 'bookkeeping/integrations',
    element: <AccountingSetup />
  },
  {
    path: 'bookkeeping/integrations/add',
    element: <AddAccountingSoftware />
  },
  { path: 'bookkeeping/map-gl', element: <AccountingConfigurationV2 /> },
  { path: 'accounting/setup', element: <AccountingSetup /> },
  { path: 'accounting/configuration', element: <AccountingConfigurationV2 /> },
  { path: 'order/based/transactions/by/store', element: <TransactionsByStore /> },
  { path: 'bookkeeping/cadence', element: <AccountingCadence /> },
  {
    path: 'bookkeeping/cadence/add',
    element: <AddAccountingCadence />
  },
  {
    path: 'bookkeeping/templates',
    element: <AccountingTemplate />
  },
  { path: 'bookkeeping/settings', element: <JESettings /> },
  { path: 'bookkeeping/select-locations', element: <BookkeepingSetupSelectLocations /> },
  { path: 'bookkeeping/configure-chart-of-accounts', element: <BookkeepingSetupConfigureChartOfAccounts /> },
  { path: 'bookkeeping/configure-variables', element: <BookkeepingSetupConfigureVariables /> },
  { path: 'bookkeeping/review-setup', element: <BookkeepingSetupReviewSetup /> },

  { path: 'pos/crunchtime/configuration', element: <CrunchtimeConfiguration /> },
  { path: 'pos/reports', element: <PosReport /> },
  { path: 'bookkeeping/history', element: <AccountingHistory /> },
  { path: 'bookkeeping/history/internal', element: <AccountingHistory /> },
  { path: 'bookkeeping/location/status', element: <AccountingLocationStatus /> },
  { path: 'royalty/overview', element: <RoyaltyOverview /> },
  { path: 'balance/tax/overview', element: <TaxOverview /> },
  { path: 'balance/tax/by-store', element: <TaxByStore /> },
  { path: 'pos/card-reconciliation', element: <CardReconciliation /> },
  { path: 'pos/third-party-reconciliation', element: <ThirdPartyReconciliation /> },
  { path: 'pos/third-party-orders-reconciliation', element: <ThirdPartyOrdersReconciliation /> }
]

export const moduleNamedRoutes = [
  {
    path: 'balance',
    children: [
      {
        index: true,
        element: (
          <Navigate
            to="income-statement"
            replace
          />
        )
      },
      { path: 'income-statement', element: <FinancePayouts /> },
      {
        path: 'transactions-and-payouts',
        element: <OrdersAndTransactions />,
        children: [
          { path: 'transactions', element: <OrdersAndTransactions /> },

          { path: 'reports', element: <OrderBasedPaying /> },
          { path: 'transactions-by-location', element: <TransactionsByStore /> }
        ]
      },
      {
        path: 'journal-entries',
        children: [
          {
            index: true,
            element: <AccountingPortal />
          },
          {
            path: '/',
            element: <AccountingPortal />
          },
          { path: 'add-bank', element: <BankAccounts /> },
          { path: 'integrations', element: <AccountingSetup /> },
          { path: 'integrations-add', element: <AddAccountingSoftware /> },
          { path: 'map-gl', element: <AccountingConfigurationV2 /> },
          { path: 'cadence', element: <AccountingCadence /> },
          { path: 'cadence-add', element: <AddAccountingCadence /> },
          { path: 'templates', element: <AccountingTemplate /> },
          { path: 'settings', element: <JESettings /> },
          { path: 'select-locations', element: <BookkeepingSetupSelectLocations /> },
          { path: 'configure-chart-of-accounts', element: <BookkeepingSetupConfigureChartOfAccounts /> },
          { path: 'configure-variables', element: <BookkeepingSetupConfigureVariables /> },
          { path: 'review-setup', element: <BookkeepingSetupReviewSetup /> },
          { path: 'history', element: <AccountingHistory /> },
          { path: 'history-internal', element: <AccountingHistory /> },
          { path: 'location-status', element: <AccountingLocationStatus /> },
          { path: 'integrations-add', element: <AddAccountingSoftware /> },
          { path: 'map-gl', element: <AccountingConfigurationV2 /> }
        ]
      },

      { path: 'payouts-list', element: <ListOfPayouts /> },
      { path: 'by-store', element: <OrderBasedPaying tab="by_store" /> },
      { path: 'bank-reconciliation', element: <OrderBasedPaying tab="bank_reconciliation" /> },
      { path: 'by-store', element: <BankReconciliation /> },

      { path: 'reports', element: <OrderBasedPaying /> },
      { path: 'transactions-non-variance-zero', element: <OrdersAndTransactions show_only_non_variance_zero /> },

      { path: 'mapping', element: <Mapping /> },
      { path: 'accounting-portal/:tab', element: <AccountingPortal /> },
      {
        path: 'accounting-portal',
        element: (
          <Navigate
            to="/journal-entries"
            replace
          />
        )
      },
      { path: 'codat-link', element: <AccountingPortal /> },
      { path: 'olo-transactions', element: <OloTransactions /> },
      { path: 'olo-customers', element: <OloCustomers /> },
      { path: 'olo-items', element: <OloItems /> },
      { path: 'plaid-accounts', element: <BankAccounts /> },

      { path: 'accounting-setup', element: <AccountingSetup /> },
      { path: 'accounting-configuration', element: <AccountingConfigurationV2 /> },
      {
        path: 'reconciliation',
        children: [
          {
            path: 'bank',
            element: <OrderBasedPaying tab="bank_reconciliation" />
          },
          {
            path: 'payout',
            element: <OrdersAndTransactions show_only_non_variance_zero />
          }
        ]
      },

      {
        path: 'pos-reconciliation',
        children: [
          {
            path: 'reports',
            element: <PosReport />
          },
          {
            path: 'third-party',
            element: <ThirdPartyReconciliation />
          },
          {
            path: 'crunchtime-configuration',
            element: <CrunchtimeConfiguration />
          }
        ]
      },
      { path: 'royalty-overview', element: <RoyaltyOverview /> },
      {
        path: 'tax',
        children: [
          {
            index: true,
            element: (
              <Navigate
                to="overview"
                replace
              />
            )
          },
          {
            path: 'overview',
            element: <TaxOverview />
          },
          { path: 'by-store', element: <TaxByStore /> }
        ]
      }
    ]
  }
]

export const balanceRoutes = [...existingRoutes, ...moduleNamedRoutes]
